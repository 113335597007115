import { style, classes } from 'typestyle'
import { px, important } from 'csx'
import { contentTypeMap } from './Content'

export const centerItems = style({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  width: px(85),
  marginLeft: 'auto',
  marginRight: 'auto',
})

export const inputContainerStyle = style({
  margin: px(8),
  display: 'flex',
  flexDirection: 'row',
  padding: `0 ${px(35)}`,
  position: 'relative',
})

export const timeoutStyle = style({
  justifyContent: important('start'),
  flexGrow: 100,
  paddingLeft: px(10),
})

export const resendPinStyle = style({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  marginTop: '2px',
  $nest: {
    'span:last-child': {
      marginLeft: '5px',
    },
    span: {
      $nest: {
        p: {
          margin: 0,
        },
      },
    },
  },
})

export const iconStyle = style({
  width: px(18),
  position: 'absolute',
  right: 0,
  top: px(19),
})

export function inputStyle(error: boolean): string {
  const inputStyle = style({
    fontSize: px(32),
    fontWeight: 700,
    width: px(44),
    height: px(50),
    margin: `0px 4px`,
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#EEEEEF',
    borderRadius: px(5),
    padding: 'unset',
    border: 'none',
    flexGrow: 100,
    outline: 'none',
    textAlign: 'center',
    lineHeight: important('normal'),
    color: error ? important('#ED2C2C') : important('#060606'),
    $nest: {
      '::placeholder': {
        color: '#808080',
      },
      ':focus': {
        outline: 'none',
      },
      ':focus::placeholder': {
        color: 'transparent',
      },
    },
  })
  return classes(inputStyle, contentTypeMap['content2'])
}

export const errorMessageStyle = style({
  margin: '0px auto',
  marginBottom: '4px',
  width: px(285),
  $nest: {
    span: {
      $nest: {
        p: {
          margin: 0,
        },
      },
    },
  },
})
