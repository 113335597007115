export const PRODUCT_ROUTE = 'PRODUCT_PAGE'
export const CAMPAIGN_FUNNEL_ROUTE = 'FUNNEL_PAGE'
export const NOT_FOUND_ROUTE = 'NOT_FOUND'
export const EXTENSION_ROUTE = 'EXTENSION_PAGE'

export const TERMS_OF_SERVICE = 'TERMS_OF_SERVICE'

export const LOGIN = 'LOGIN'

export const BUY = 'BUY'
export const BUY_CONFIRM = 'BUY_CONFIRM'
export const BUY_SUCESS = 'BUY_SUCESS'
export const BUY_FAIL = 'BUY_FAIL'

export const CHECK_BALANCES = 'CHECK_BALANCES'
export const PERSONAL_OFFER = 'PERSONAL_OFFER'
export const MY_SERVICES = 'MY_SERVICES'
export const TOPUP = 'TOPUP'
export const TOPUP_CALLBACK = 'TOPUP_CALLBACK'
export const TOPUP_PROCESS = 'TOPUP_PROCESS'
export const TOPUP_FAIL = 'TOPUP_FAIL'
export const SIGNOUT_CONFIRM = 'SIGNOUT_CONFIRM'
export const SIGNOUT_CONFIRM_SIGNEDOUT = 'SIGNOUT_CONFIRM_SIGNEDOUT'
