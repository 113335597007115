import { style } from 'typestyle'

export const container = style({
  paddingTop: '91px',
})

export const title = style({
  fontWeight: 'bold',
  paddingLeft: '7px',
})

export const buttons = style({
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: '21px',
})

export const buttonStyle = {
  marginRight: '10px',
  marginBottom: '7px',
  padding: '10px 40px',
  borderColor: 'rgba(0, 11, 48, 0.3)',
  color: 'rgba(0, 11, 48, 0.4)',
  fontWeight: 'bold',
}

export const buttonSelectedStyle = {
  ...buttonStyle,
  background: '#000B30',
  color: '#fff',
  fontWeight: 'normal',
  borderColor: '#000B30',
}
