const url = (path: string): string => `${process.env.VUE_APP_BUCKET_URL}${path}`
export const urlBuilder = (
  projectName: string,
  target: 'icons' | 'loaders',
  fileName: string,
): string =>
  url(`/assets/${projectName.replaceAll(' ', '_')}/${target}/${fileName}`)

export const iconAsset = (projectName: string, iconName: string): string =>
  urlBuilder(projectName, 'icons', `${iconName}.svg`)

export const processName = (name: string): string =>
  name.replace(/\?| |\*|#|\[|]/g, '_').replace(/_+/g, '_')

export const imageNameFromUrl = (url: string): string =>
  url.split('/').pop() || url

export default url
