import { inject } from 'vue'

const defaultStyle = {
  brandColor: '',
  ctaColor: '',
  ctaTextColor: '',
  googleFontPath: '',
  iconColor: '',
  cardDesign: '',
}
export default (): typeof defaultStyle => inject('style', defaultStyle)
