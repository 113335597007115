import { CAROUSEL_PAGE, ERROR_PAGE, LEARN_MORE_PAGE, READ_MORE_PAGE, SETTINGS_PAGE } from '@/constants'
import component from '@/service/componentBuilder'
import { IPage } from '@/graphql/productPage/models'
import { FfComponent } from '@/components/types'
import { useProject } from '@/composables'

export default (pageDetails: IPage, verticalScrolling = false): FfComponent[] | null => {
  verticalScrolling = processVerticalScrolling(verticalScrolling)

  if (verticalScrolling) {
    document.documentElement.classList.add('snap-enabled')
    document.body.classList.add('snap-enabled')
  } else {
    document.documentElement.classList.remove('snap-enabled')
    document.body.classList.remove('snap-enabled')
  }

  switch (pageDetails.__typename) {
    case CAROUSEL_PAGE: {
      return [
        component.header(pageDetails.title, pageDetails.description),
        verticalScrolling ? component.cardList(pageDetails.cards) : component.carousel(pageDetails.cards),
      ]
    }
    case ERROR_PAGE:
      return [
        component.iconLarge(pageDetails.iconName),
        component.header(pageDetails.title, pageDetails.subtitle),
        ...component.buttons(pageDetails.buttons),
      ]
    case LEARN_MORE_PAGE:
      return [
        component.bannerBox(pageDetails.title),
        component.longContent(pageDetails.description),
        component.stickyButtons(pageDetails.buttons),
      ]
    case READ_MORE_PAGE:
      return [
        component.bannerBox(pageDetails.title),
        component.table(pageDetails.readMoreRows),
        component.stickyButtons(pageDetails.buttons),
      ]
    case SETTINGS_PAGE:
      return [
        component.langSwitch(pageDetails.languageSwitch, pageDetails.settingsPageItems),
        ...component.buttons(pageDetails.options, 'button_option'),
      ]
    default:
      // catch custom pages here
      console.log('pagetype not found')
      return null
  }
}

function processVerticalScrolling(verticalScrolling: boolean) {
  const { state: project } = useProject()

  switch (project.config?.branding.cardDesign) {
    case 'newCard':
      return verticalScrolling
    case 'e2eCard':
      return true
    default:
      return false
  }
}
