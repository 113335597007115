import {
  BalancesResponse,
  BillsResponse,
  GetOffersResponse,
  InfoResponse,
  PostOrderRequest,
} from '@/models/api/capi'
import { useLogger, useProject, useUser } from './'
import axios, { AxiosResponse } from 'axios'
import platformProxy from '@/utils/platformProxy'
import { getDeviceId, getUserId } from '@amplitude/analytics-browser'

interface UseCapi {
  getBalances: (
    category: string,
  ) => Promise<AxiosResponse<BalancesResponse | BalancesResponse[]>>
  getBills: (status: string) => Promise<AxiosResponse<BillsResponse>>
  getInfo: () => Promise<AxiosResponse<InfoResponse>>
  getOffers: (
    status: string,
    category?: string,
    tag?: string,
    type?: string,
    payment?: string,
  ) => Promise<AxiosResponse<GetOffersResponse[]>>
  postOrder: (order: PostOrderRequest) => Promise<void>
}

const useCapi = (): UseCapi => {
  const { state: project } = useProject()
  const { state: user } = useUser()
  const { traceId } = useLogger()

  const getBalances = async (
    category: string,
  ): Promise<AxiosResponse<BalancesResponse | BalancesResponse[]>> => {
    return {
      data: [
        {
          id: 'AAA',
          category: 'data',
          name: 'BBB',
          amount: 99,
          initialAmount: 149,
          units: 'MB',
          source: 'addon',
          unlimited: false,
          retrieved: '2023-08-22T12:23:52.416Z',
          expires: '2023-08-22T12:23:52.416Z',
          description: '',
        },
      ],
    } as AxiosResponse

    // return await axios.get(platformProxy(`capi/balances`), {
    //   params: {
    //     customerId: project.projectName,
    //     customerUserId: user.customerUserId,
    //     category: category,
    //     language: user.language,
    //   },
    // })
  }

  const getBills = async (
    status: string,
  ): Promise<AxiosResponse<BillsResponse>> => {
    return await axios.get(platformProxy(`capi/bills`), {
      params: {
        customerId: project.projectName,
        customerUserId: user.customerUserId,
        status: status,
      },
    })
  }

  const getInfo = async (): Promise<AxiosResponse<InfoResponse>> => {
    return await axios.get(platformProxy(`capi/info`), {
      params: {
        customerId: project.projectName,
        customerUserId: user.customerUserId,
      },
    })
  }

  const getOffers = async (
    status: string,
    category?: string,
    tag?: string,
    type?: string,
    payment?: string,
  ): Promise<AxiosResponse<GetOffersResponse[]>> => {
    return {
      data: [
        {
          id: 'test',
          name: 'CCC',
        },
      ],
    } as AxiosResponse

    // return await axios.get(platformProxy(`capi/offers`), {
    //   params: {
    //     customerId: project.projectName,
    //     customerUserId: user.customerUserId,
    //     status,
    //     category,
    //     tag,
    //     type,
    //     payment,
    //   },
    // })
  }

  const postOrder = async (order: PostOrderRequest): Promise<void> => {
    // await axios.post(platformProxy(`capi/order`), {
    //   customerId: project.projectName,
    //   customerUserId: user.customerUserId,
    //   channelUserId: user.channelUserId,
    //   sessionId: user.sessionId,
    //   projectId: project.projectId,
    //   traceId: traceId.value,
    //   order,
    //   amplitudeUserId: getUserId(),
    //   amplitudeDeviceId: getDeviceId(),
    // })
  }

  return { getBills, getBalances, getInfo, getOffers, postOrder }
}

const instance = useCapi()

export default (): UseCapi => instance
