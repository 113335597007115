import { useProject } from '@/composables'

import {
  createRouter,
  createWebHistory,
  RouteLocationRaw,
  Router,
  RouteRecordRaw,
  RouterView,
} from 'vue-router'
import {
  CAMPAIGN_FUNNEL_ROUTE,
  NOT_FOUND_ROUTE,
  PRODUCT_ROUTE,
  EXTENSION_ROUTE,
  TERMS_OF_SERVICE,
} from '@/constants/routes'
import NotFoundComponent from '@/views/NotFound.vue'
import FunnelComponent from '@/views/Funnel.vue'
import ProductComponent from '@/views/Product.vue'
import TermsOfServiceComponent from '@/views/TermsOfService.vue'

const redirectToHomePage = (): RouteLocationRaw => {
  const { state: project } = useProject()
  return {
    name: PRODUCT_ROUTE,
    params: { pageId: project.homepageId },
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/page/:pageId?',
    name: PRODUCT_ROUTE,
    component: ProductComponent,
    beforeEnter: (to, _from, next) =>
      !to.params.pageId ? next(redirectToHomePage()) : next(),
  },
  {
    path: '/campaign/funnel/:funnelId?',
    name: CAMPAIGN_FUNNEL_ROUTE,
    component: FunnelComponent,
    beforeEnter: (to, _from, next) =>
      !to.params.funnelId ? next(redirectToHomePage()) : next(),
  },
  {
    path: '/terms-of-service',
    name: TERMS_OF_SERVICE,
    component: TermsOfServiceComponent,
  },
  {
    path: '/extension',
    name: EXTENSION_ROUTE,
    component: RouterView,
  },
  {
    path: '/',
    redirect: () => {
      const { state: project } = useProject()
      return {
        name: PRODUCT_ROUTE,
        params: { pageId: project.homepageId },
      }
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: NOT_FOUND_ROUTE,
    component: NotFoundComponent,
  },
]
let routerInstance: Router
const router = (base: string): Router => {
  if (!routerInstance)
    routerInstance = createRouter({
      history: createWebHistory(base || '/'),
      routes,
    })
  return routerInstance
}

// Function responsible of importing appropriate routes for current project
export const initExtensionRoutes = async (projectName: string) => {
  try {
    //TODO: optimization of webpack chunks
    const {
      routes,
    }: {
      routes: Array<RouteRecordRaw>
    } = require(`@/views/extensions/${projectName}`)
    routes.forEach((route) => routerInstance.addRoute(EXTENSION_ROUTE, route))
  } catch {}
}

export default router
