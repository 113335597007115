import { useUser, useProject, useLogger } from '@/composables'
import {
  LOGIN,
  BUY,
  BUY_CONFIRM,
  BUY_FAIL,
  BUY_SUCESS,
  CHECK_BALANCES,
  PERSONAL_OFFER,
  MY_SERVICES,
  TOPUP,
  TOPUP_CALLBACK,
  TOPUP_FAIL,
  TOPUP_PROCESS,
  SIGNOUT_CONFIRM,
  SIGNOUT_CONFIRM_SIGNEDOUT,
  EXTENSION_ROUTE,
} from '@/constants/routes'
import {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from 'vue-router'
import { RouterView } from 'vue-router'
import { redirectToHeaderEnrichment } from '@/utils'
import {
  HEADER_ENRICHMENT_ATTEMPT,
  HEADER_ENRICHMENT_FAILURE,
  HEADER_ENRICHMENT_SUCCESS,
} from '@/constants/tracking'

const extensionGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
): Promise<void> => {
  const { setCustomerUserId } = useUser()
  const mockLogin = localStorage.getItem('mock-login')

  if (!mockLogin && to.meta.requiresAuth) {
    return next({ name: LOGIN, query: { redirect: to.fullPath } })
  } else {
    setCustomerUserId(mockLogin)
  }

  // const { traceId, sendEvent } = useLogger()
  // const { registrationStatus, setCustomerUserId, state: user } = useUser()
  // const { state: project } = useProject()
  // const regResp = await registrationStatus()
  // if (!regResp.registered && to.meta.requiresAuth) {
  //   const status = regResp.registration.heStatus
  //   if (status) {
  //     if (status !== 'success') {
  //       sendEvent(
  //         EXTENSION_ROUTE,
  //         HEADER_ENRICHMENT_FAILURE,
  //         to.fullPath,
  //         status,
  //       )

  //       // Query `redirect` here is used to know where to redirect after successful sign in
  //       return next({ name: LOGIN, query: { redirect: to.fullPath } })
  //     } else {
  //       sendEvent(EXTENSION_ROUTE, HEADER_ENRICHMENT_SUCCESS, to.fullPath)

  //       setCustomerUserId(regResp.registration.customerUserId || null)
  //     }
  //   } else {
  //     const sessionId = user.sessionId || ''
  //     let redirectURL = `${window.location.origin}${to.fullPath}`

  //     if (project.domain.base !== '/') {
  //       redirectURL = `${window.location.origin}${project.domain.base}${to.fullPath}`
  //     }

  //     sendEvent(EXTENSION_ROUTE, HEADER_ENRICHMENT_ATTEMPT, to.fullPath)

  //     return redirectToHeaderEnrichment(
  //       sessionId,
  //       project.projectName,
  //       project.projectId,
  //       traceId.value,
  //       redirectURL,
  //     )
  //   }
  // }
  next()
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    component: RouterView,
    beforeEnter: extensionGuard,
    children: [
      /**
       * @Route(path="/login", auth="false", name="Login")
       */
      {
        path: 'Login',
        name: LOGIN,
        component: () => import('./views/Login'),
        meta: {
          requiresAuth: false,
        },
      },
      /**
       * @Route(path="/CheckBalances", auth="true", name="CheckBalances")
       */
      {
        path: 'CheckBalances',
        name: CHECK_BALANCES,
        component: () => import('./views/CheckBalances'),
        meta: {
          requiresAuth: true,
        },
      },
      /**
       * @Route(path="/PersonalOffer", auth="true", name="PersonalOffer")
       */
      {
        path: 'PersonalOffer',
        name: PERSONAL_OFFER,
        component: () => import('./views/PersonalOffer'),
        meta: {
          requiresAuth: true,
        },
      },
      /**
       * @Route(path="/MyServices", auth="true", name="MyServices")
       */
      {
        path: 'MyServices',
        name: MY_SERVICES,
        component: () => import('./views/MyServices'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'Buy',
        name: BUY,
        component: RouterView,
        children: [
          {
            path: '',
            redirect: { name: BUY_CONFIRM },
          },
          /**
           * @Route(path="/Buy/BuyConfirm", auth="true", , name="BuyConfirm")
           */
          {
            path: 'BuyConfirm',
            name: BUY_CONFIRM,
            component: () => import('./views/BuyConfirm'),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'BuySuccess',
            name: BUY_SUCESS,
            component: () => import('./views/BuySuccess'),
            meta: {
              requiresAuth: false,
            },
          },
          {
            path: 'BuyFail',
            name: BUY_FAIL,
            component: () => import('./views/BuyFail'),
            meta: {
              requiresAuth: false,
            },
          },
        ],
      },
      {
        path: 'Topup',
        component: RouterView,
        children: [
          /**
           * @Route(path="/Topup", auth="true", , name="Topup")
           */
          {
            path: '',
            name: TOPUP,
            component: () => import('./views/Topup'),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'TopupCallback',
            name: TOPUP_CALLBACK,
            component: () => import('./views/TopupCallback'),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'TopupProcess',
            name: TOPUP_PROCESS,
            component: () => import('./views/TopupProcess'),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'TopupFail',
            name: TOPUP_FAIL,
            component: () => import('./views/TopupFail'),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: 'SignOut',
        component: RouterView,
        children: [
          /**
           * @Route(path="/SignOut/SignOutConfirm", auth="true", , name="SignOutConfirm")
           */
          {
            path: 'SignOutConfirm',
            name: SIGNOUT_CONFIRM,
            component: () => import('./views/SignOutConfirm'),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'SignedOut',
            name: SIGNOUT_CONFIRM_SIGNEDOUT,
            component: () => import('./views/SignedOut'),
            meta: {
              requiresAuth: false,
            },
          },
        ],
      },
    ],
  },
]

export { routes }
