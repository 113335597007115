import { cssRaw } from 'typestyle'

export default (fontPath: string, boldFontPath: string): void => {
  if (!fontPath && !boldFontPath) {
    return
  }

  let css = ''

  if (fontPath) {
    const url = new URL(fontPath)
    url.protocol = 'https:'

    css += `@font-face {
      font-family: "PrimaryFont";
      src: url('${url.href}');
    }\n`
  }

  if (boldFontPath) {
    const url = new URL(boldFontPath)
    url.protocol = 'https:'

    css += `@font-face {
      font-family: "PrimaryFont";
      src: url('${url.href}');
      font-weight: bold;
    }\n`
  }

  css += `* {
    font-family: "PrimaryFont";
    font-synthesis: weight style small-caps;
  }\n`

  return cssRaw(css)
}
