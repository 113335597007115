import {
  ProductPageIdByPageNameQueryResponse,
  ProductPageQueryResponse,
} from './models'
import {
  PRODUCT_PAGE_ID_BY_PAGE_NAME_QUERY,
  PRODUCT_PAGE_QUERY,
} from './queries'
import { UseFetch, useFetch } from '../composables/useFetch'
import { computed } from 'vue'
import pageBuilder from '@/service/pageBuilder'
import { FfComponent } from '@/components/types'

export default {
  getProductPage: (
    id: string,
    productId: string,
    language: string,
  ): UseFetch<{
    page: FfComponent[] | null
    pageType: string
  }> => {
    const { loading, refetch, result, error } =
      useFetch<ProductPageQueryResponse>(
        PRODUCT_PAGE_QUERY,
        { id, productId, language },
        {
          fetchPolicy: 'cache-and-network',
        },
      )
    const pickedResult = computed(() => {
      return (
        result.value && {
          page: pageBuilder(result.value.productPageTranslated.pageDetails),
          pageType: result.value.productPageTranslated.pageDetails.__typename,
        }
      )
    })

    return { loading, refetch, result: pickedResult, error }
  },
  getProductPageIdByPageName: (
    name: string,
    productId: string,
  ): UseFetch<ProductPageIdByPageNameQueryResponse> =>
    useFetch<ProductPageIdByPageNameQueryResponse>(
      PRODUCT_PAGE_ID_BY_PAGE_NAME_QUERY,
      { name, productId },
      {
        fetchPolicy: 'cache-and-network',
      },
    ),
  getRawProductPage: (
    id: string,
    productId: string,
    language: string,
  ): UseFetch<ProductPageQueryResponse> =>
    useFetch<ProductPageQueryResponse>(
      PRODUCT_PAGE_QUERY,
      { id, productId, language },
      {
        fetchPolicy: 'network-only',
      },
    ),
}
