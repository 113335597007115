import { App } from '@vue/runtime-core'

import BannerBox from '@/components/ui/BannerBox.vue'
import Button from '@/components/ui/Button.vue'
import Card from '@/components/ui/Card.vue'
import CardList from '@/components/ui/CardList.vue'
import Carousel from '@/components/ui/Carousel.vue'
import Content from '@/components/ui/Content.vue'
import ContentRender from '@/components/ui/ContentRender.vue'
import Header from '@/components/ui/Header.vue'
import IconBase from '@/components/ui/IconBase.vue'
import LanguageSwitch from '@/components/ui/LanguageSwitch.vue'
import Loader from '@/components/ui/Loader.vue'
import LoadingToast from '@/components/ui/LoadingToast.vue'
import Menu from '@/components/ui/Menu.vue'
import Modal from '@/components/ui/Modal.vue'
import ReadMoreModal from '@/components/ui/ReadMoreModal.vue'
import ReadMoreModalTable from '@/components/ui/ReadMoreModalTable.vue'
import NotificationPopup from '@/components/ui/NotificationPopup.vue'
import NumberInput from '@/components/ui/NumberInput.vue'
import NumberWithModal from '@/components/ui/NumberWithModal.vue'
import PinInput from '@/components/ui/PinInput.vue'
import Popup from '@/components/ui/Popup.vue'
import ScrollSelectInput from '@/components/ui/ScrollSelectionInput.vue'
import StickyButtons from '@/components/ui/StickyButtons.vue'
import Table from '@/components/ui/Table.vue'
import TextCard from '@/components/ui/TextCard.vue'
import Timeout from '@/components/ui/Timeout.vue'
import TopNavBar from '@/components/ui/TopNavBar.vue'
import SettingsDrawer from '@/components/ui/SettingsDrawer.vue'
import TermsFooter from '@/components/ui/TermsFooter.vue'
import LeadFormModal from '@/components/ui/LeadFormModal.vue'
import LeadFormTextField from '@/components/ui/LeadFormTextField.vue'

export default {
  install: (app: App): void => {
    app.component('ff-banner-box', BannerBox)
    app.component('ff-button', Button)
    app.component('ff-card', Card)
    app.component('ff-card-list', CardList)
    app.component('ff-carousel', Carousel)
    app.component('ff-content', Content)
    app.component('ff-content-render', ContentRender)
    app.component('ff-header', Header)
    app.component('ff-icon-base', IconBase)
    app.component('ff-language-switch', LanguageSwitch)
    app.component('ff-loader', Loader)
    app.component('ff-loading-toast', LoadingToast)
    app.component('ff-menu', Menu)
    app.component('ff-modal', Modal)
    app.component('ff-read-more-modal', ReadMoreModal)
    app.component('ff-read-more-modal-table', ReadMoreModalTable)
    app.component('ff-notification-popup', NotificationPopup)
    app.component('ff-number-input', NumberInput)
    app.component('ff-number-with-modal', NumberWithModal)
    app.component('ff-pin-input', PinInput)
    app.component('ff-popup', Popup)
    app.component('ff-scroll-select-input', ScrollSelectInput)
    app.component('ff-sticky-buttons', StickyButtons)
    app.component('ff-table', Table)
    app.component('ff-text-card', TextCard)
    app.component('ff-timeout', Timeout)
    app.component('ff-top-nav-bar', TopNavBar)
    app.component('ff-settings-drawer', SettingsDrawer)
    app.component('ff-terms-footer', TermsFooter)
    app.component('ff-lead-form-modal', LeadFormModal)
    app.component('ff-lead-form-text-field', LeadFormTextField)
  },
}
