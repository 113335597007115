import { localCustomOrigins } from './localCustomOrigins'

const customOrigins: {
  [key: string]: { origin: string; base: string }
} = {
  ...localCustomOrigins,

  'https://fs2.fastforward.ai': {
    origin: 'https://dev-pwatesttwo.swashly.eu',
    base: '/beelinemore',
  },
  'http://beeline.ge': {
    origin: 'https://beelinegeorgia.swashly.eu',
    base: '/fforward/beelinemore',
  },
  'https://beeline.ge': {
    origin: 'https://beelinegeorgia.swashly.eu',
    base: '/fforward/beelinemore',
  },
  'http://cellfie.ge': {
    origin: 'https://beelinegeorgia.swashly.eu',
    base: '/fforward',
  },
  'https://cellfie.ge': {
    origin: 'https://beelinegeorgia.swashly.eu',
    base: '/fforward',
  },
}

export default customOrigins
