import { style } from 'typestyle'

export const container = style({
  maxWidth: '285px',
  margin: '24px auto 0 auto',
  textAlign: 'center',
  fontSize: '12px',
  color: 'rgba(0, 0, 0, 0.5)',
  scrollSnapAlign: 'end',
})

export const link = style({
  color: 'rgba(0, 0, 0, 0.8)',
})
