import { deg, px, rotate, scale } from 'csx'
import { keyframes, style } from 'typestyle'
import { ffBlack } from '../../styles/constants/colors'

const chaseAnimation = keyframes({
  '100%': { transform: rotate(deg(360)) },
})

const chaseDotAnimation = keyframes({
  '80%': { transform: rotate(deg(360)) },
  '100%': { transform: rotate(deg(360)) },
})

const chaseDotBeforeAnimation = keyframes({
  '50%': { transform: scale(0.4) },
  '100%': { transform: scale(1.0) },
  '0%': { transform: scale(1.0) },
})

const spinnerWrapper = (width: number, height: number): string =>
  style({
    position: 'relative',
    display: 'inline-block',
    width: px(width),
    height: px(height),
  })

const spinnerChase = style({
  animationName: chaseAnimation,
  animationDuration: '2.5s',
  animationIterationCount: 'infinite',
  animationFillMode: 'both',
  animationTimingFunction: 'linear',
  position: 'absolute',
  left: 0,
  top: 0,
  width: 'inherit',
  height: 'inherit',
})

const spinnerDot = style({
  width: '100%',
  height: '100%',
  position: 'absolute',
  left: 0,
  top: 0,
  animationName: chaseDotAnimation,
  animationDuration: '2.0s',
  animationIterationCount: 'infinite',
  animationTimingFunction: 'ease-in-out',
  animationFillMode: 'both',

  $nest: {
    '&::before': {
      content: "''",
      display: 'block',
      width: '25%',
      height: '25%',
      backgroundColor: ffBlack,
      borderRadius: '100%',
      animationName: chaseDotBeforeAnimation,
      animationDuration: '2.0s',
      animationIterationCount: 'infinite',
      animationTimingFunction: 'ease-in-out',
      animationFillMode: 'both',
    },
    '&:nth-child(1)': {
      animationDelay: '-1.1s',
      $nest: {
        '::before': {
          animationDelay: '-1.1s',
        },
      },
    },
    '&:nth-child(2)': {
      animationDelay: '-1.0s',
      $nest: {
        '&::before': {
          animationDelay: '-1.0s',
          backgroundColor: '#2E2A2C',
        },
      },
    },
    '&:nth-child(3)': {
      animationDelay: '-0.9s',
      $nest: {
        '&::before': {
          animationDelay: '-0.9s',
          backgroundColor: '#393638',
        },
      },
    },
    '&:nth-child(4)': {
      animationDelay: '-0.8s',
      $nest: {
        '&::before': {
          animationDelay: '-0.8s',
          backgroundColor: '#454244',
        },
      },
    },
    '&:nth-child(5)': {
      animationDelay: '-0.7s',
      $nest: {
        '&::before': {
          animationDelay: '-0.7s',
          backgroundColor: '#514F51',
        },
      },
    },
    '&:nth-child(6)': {
      animationDelay: '-0.6s',
      $nest: {
        '&::before': {
          animationDelay: '-0.6s',
          backgroundColor: '#5B5A5B',
        },
      },
    },
  },
})

export { spinnerWrapper, spinnerChase, spinnerDot }
