import { style } from 'typestyle'
import { px } from 'csx'

export const rowStyle = style({
  background: 'rgba(255, 255, 255, 0.9)',
  display: 'flex',
  padding: '22px 22px 22px 26px',
  margin: '10px 0',
  $nest: { '*': { margin: 0 } },
  borderRadius: px(20),
})

export const titleStyle = style({
  margin: '0 0 12px 0',
})

export const contentContainer = style({
  flexBasis: '1',
  flexGrow: '1',
  paddingRight: '8px',
  transition: 'height 0.3s',
  overflow: 'hidden',
})

export const buttonCollapseContainer = style({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export const buttonCollapse = (isOpen: boolean): string =>
  style({
    display: 'flex',
    flexDirection: 'row-reverse',
    width: px(20),
    height: px(20),
    backgroundRepeat: 'no-repeat',
    flexWrap: 'nowrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    background: isOpen
      ? "url('/icons/m.svg')"
      : "url('/icons/p.svg')",
  })
