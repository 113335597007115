interface AmountGenerator {
  options: Array<Array<{ label: string; value: string }>>
  defaultValue: string
}

const generateAmounts = (
  startAmount: number,
  endAmount: number,
  currency: string,
): AmountGenerator => {
  const options: Array<{ label: string; value: string }> = []
  for (let i = startAmount; i <= endAmount; i++)
    options.push({
      label: `${currency} ${i}`,
      value: `${i * 100}`,
    })
  return { options: [options], defaultValue: `${startAmount * 100}` }
}

export { generateAmounts }
