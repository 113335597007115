import { useUser } from '@/composables'
import { track } from '@amplitude/analytics-browser'

import { Ref, ref } from 'vue'
import { v4 as uuid } from 'uuid'
import useProject from './useProject'
import { Action, PAGE_VIEW, TrackingInfo } from '@/constants/tracking'
import { useFetchAsPromise } from '@/graphql/composables/useFetch'
import { funnelService, productPageService } from '@/graphql'
import { SETTINGS_PAGE } from '@/constants'
import { RouteLocationNormalizedLoaded } from 'vue-router'
import {
  CAMPAIGN_FUNNEL_ROUTE,
  EXTENSION_ROUTE,
  PRODUCT_ROUTE,
} from '@/constants/routes'

export interface LogRecordError {
  type: string
  description: string
  stackTrace?: string
  statusCode?: string
}

export interface LogServiceResponse {
  response?: string
  error?: string
}

export interface LogRecordDeviceInfo {
  traceId: string
  cookieEnabled?: boolean
  language?: string
  platform?: string
  userAgent?: string
  vendor?: string
}

export interface EventTracking {
  traceId?: string
  name: string
  category: string
  action: string
  description?: string
  error?: LogRecordError
  timestamp: number
  projectId?: string
}

interface PageDetails {
  id: string
  name?: string
  type?: string
}

async function getProductPageDetails(
  id: string,
  productId: string,
  language: string,
): Promise<PageDetails> {
  const data = await useFetchAsPromise(() =>
    productPageService.getRawProductPage(id, productId, language),
  )

  switch (data.result.productPageTranslated.pageDetails.__typename) {
    case SETTINGS_PAGE:
      return {
        id,
        type: SETTINGS_PAGE,
        name: SETTINGS_PAGE,
      }
    default:
      return {
        id,
        type: data.result.productPageTranslated.pageDetails.__typename,
        name: data.result.productPageTranslated.pageDetails.title,
      }
  }
}

async function getFunnelPageDetails(
  id: string,
  projectId: string,
  language: string,
): Promise<PageDetails> {
  const data = await useFetchAsPromise(() =>
    funnelService.getRawFunnel(id, projectId, language),
  )

  return {
    id,
    name: data.result.getFunnelTranslated.pageDetails.title,
    type: CAMPAIGN_FUNNEL_ROUTE,
  }
}

async function getPageDetails(
  route: RouteLocationNormalizedLoaded,
  projectId: string,
  productId: string,
  language: string,
): Promise<PageDetails> {
  try {
    switch (route.name) {
      case PRODUCT_ROUTE:
        return await getProductPageDetails(
          route.params.pageId.toString(),
          productId,
          language,
        )
      case CAMPAIGN_FUNNEL_ROUTE:
        return await getFunnelPageDetails(
          route.params.funnelId.toString(),
          projectId,
          language,
        )
      case EXTENSION_ROUTE:
        return {
          id: route.fullPath,
          name: route.params.extensionId.toString(),
          type: EXTENSION_ROUTE,
        }
    }
  } catch (e) {
    console.log('Something went wrong on retrieving page details: ', e)
  }
  return {
    id: route.fullPath,
  }
}

interface UseLogger {
  traceId: Ref<string>
  country: Ref<string>
  sendEvent: (
    action: Action,
    pageName: RouteLocationNormalizedLoaded,
    customFields?: Record<string, any>,
  ) => void
  sendPageview: (pageName: RouteLocationNormalizedLoaded) => Promise<void>
  initialize: () => Promise<void>
}

const LoggingService = (): UseLogger => {
  const sessionId = ref<string>('')
  const country = ref<string>('')

  const initialize = async () => {
    const createdSessionId = window.sessionStorage.getItem('uuid') || uuid()
    window.sessionStorage.setItem('uuid', createdSessionId)
    sessionId.value = createdSessionId
  }

  const sendPageview = async (
    pageRoute: RouteLocationNormalizedLoaded,
    customFields?: Record<string, any>,
  ) => {
    const { state: project } = useProject()
    const { state: user } = useUser()

    const pageDetails = await getPageDetails(
      pageRoute,
      project.projectId,
      project.productId,
      user.language,
    )

    const trackingInfo: TrackingInfo = {
      version: 2,
      pageId: pageDetails.id,
      pageName: pageDetails.name,
      pageRoute: pageRoute.fullPath,
      projectId: project.projectId,
      channelId: user.channelId,
      channelUserId: user.channelUserId || undefined,
      language: user.language,
      sessionId: sessionId.value,
      customFields,
    }

    // console.log('SEND_EVENT', PAGE_VIEW, trackingInfo)
    if (process.env.VUE_APP_AMPLITUDE_API_KEY) {
      track(PAGE_VIEW, trackingInfo)
    }
  }

  const sendEvent = async (
    action: Action,
    pageRoute: RouteLocationNormalizedLoaded,
    customFields?: Record<string, any>,
  ) => {
    const { state: user } = useUser()
    const { state: project } = useProject()

    const pageDetails = await getPageDetails(
      pageRoute,
      project.projectId,
      project.productId,
      user.language,
    )

    const trackingInfo: TrackingInfo = {
      version: 2,
      pageId: pageDetails.id,
      pageName: pageDetails.name,
      pageRoute: pageRoute.fullPath,
      projectId: project.projectId,
      channelId: user.channelId,
      channelUserId: user.channelUserId || undefined,
      language: user.language,
      sessionId: sessionId.value,
      customFields,
    }

    // console.log('SEND_EVENT', action, trackingInfo)
    if (process.env.VUE_APP_AMPLITUDE_API_KEY) {
      track(action, trackingInfo)
    }
  }

  return {
    traceId: sessionId,
    country,
    sendEvent,
    sendPageview,
    initialize,
  }
}

const instance = LoggingService()

export default (): UseLogger => instance
