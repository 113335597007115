import { calc, important, percent, px } from 'csx'
import { style } from 'typestyle'

import { ffWhite } from '@/styles/constants/colors'
import { NestedCSSProperties } from 'typestyle/lib/types'

const cardStyle = (contentAutoHeight: boolean, center: boolean) => {
  let base: NestedCSSProperties = {
    height: contentAutoHeight ? 'auto' : percent(100),
    overflow: 'scroll',
    '-ms-overflow-style': 'none', // IE 10+
    scrollbarWidth: 'none', // Firefox
    $debugName: 'card',
    $nest: {
      '&::-webkit-scrollbar': {
        display: 'none', // Safari and Chrome
      },
    },
  }

  if (center) {
    base = {
      ...base,
      display: 'flex',
      justifyContent: 'center',
    }
  }

  return style(base)
}

const cardContentMargin = 4
const cardContentStyle = style({
  borderRadius: px(5),
  display: 'flex',
  flexDirection: 'column',
  height: calc(`${percent(100)}  - ${px(cardContentMargin * 2)}`),
  overflow: 'hidden',
  width: px(285 - 2 * cardContentMargin),
  margin: px(cardContentMargin),
  $debugName: 'card_content',
})

const cardContentStyleNew = style({
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderRadius: px(20),
  display: 'flex',
  flexDirection: 'column',
  height: px(471.113),
  overflow: 'hidden',
  width: px(340),
  margin: px(cardContentMargin),
  $debugName: 'card_content',
})

const cardContentStyleE2e = style({
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: px(520),
  overflow: 'hidden',
  width: '100%',
  $debugName: 'card_content',
})

const cardTextStyle = style({
  background: important(ffWhite),
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: `8px 25px 16px 25px`,
  borderRadius: '20px 20px 0 0',
  marginTop: '-20px',
})

const cardTextStyleNew = style({
  background: 'transparent',
  display: 'flex',
  flexDirection: 'column',
  padding: `${px(18)} ${px(40)}`,
  color: ffWhite,
  fontFamily: 'PrimaryFont',
})

const cardTextStyleE2e = style({
  background: 'transparent',
  padding: `0 0 18px 18px`,
  color: ffWhite,
  fontFamily: 'PrimaryFont',
})

const cardButtonStyle = style({
  marginTop: 'auto',
  padding: '0px 25px 16px 25px',
  backgroundColor: important(ffWhite),
})

const cardButtonStyleNew = style({
  marginTop: 'auto',
  padding: '0px 25px 31px 25px',
  fontFamily: 'PrimaryFont',
  fontWeight: px(400),
})

const cardButtonStyleE2e = style({
  padding: '0px 18px 18px 0px',
  fontFamily: 'PrimaryFont',
  fontWeight: px(400),
  minWidth: '172px',
})
const cardImageStyle = style({
  width: percent(100),
  height: px(150),
  $nest: {
    img: {
      width: percent(100),
      height: 'inherit',
    },
  },
})

const cardImageStyleE2e = style({
  width: percent(100),
  flex: '1',
})

const newCardWrapper = style({
  flex: '1',
  display: 'flex',
  flexDirection: 'column',
})

const e2eCardWrapper = style({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '19px',
})

const cardDescription = style({ padding: '8px 0', position: 'relative', marginLeft: '18px', marginBottom: '24px' })

export {
  cardStyle,
  cardContentStyle,
  cardContentStyleNew,
  cardTextStyle,
  cardTextStyleNew,
  cardButtonStyle,
  cardButtonStyleNew,
  cardImageStyle,
  cardContentStyleE2e,
  newCardWrapper,
  e2eCardWrapper,
  cardTextStyleE2e,
  cardImageStyleE2e,
  cardButtonStyleE2e,
  cardDescription,
}
