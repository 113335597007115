import { ffDisabled } from '@/styles/constants/colors'
import { style } from 'typestyle'

const scrollStyleExtention = style({
  width: '285px',
  marginTop: '8px',
  marginBottom: '8px',
  margin: 'auto',
  $nest: {
    '.scroll-picker-container': {
      height: '206px',
      $nest: {
        '.center-overlay': {
          $nest: {
            '.center-overlay-custom': {
              border: '1px solid #EBEBEB',
              borderRadius: '5px',
            },
          },
        },
      },
    },
    '.pad-overlay': {
      $nest: {
        '.pad-top-overlay-custom': {
          background: 'none',
        },
        '.pad-bottom-overlay-custom': {
          background: 'none',
        },
      },
    },
  },
})

const activeClass = style({
  fontSize: '15px',
  fontWeight: 'normal',
  whiteSpace: 'break-spaces',
  textAlign: 'center',
  $nest: {
    '&:first-line': {
      fontWeight: 'bold',
      fontSize: '32px',
    },
  },
})

const inactiveClass = style({
  fontSize: '15px',
  whiteSpace: 'break-spaces',
  color: ffDisabled,
  textAlign: 'center',
  $nest: {
    '&:first-line': {
      fontWeight: 'bold',
      fontSize: '32px',
    },
  },
})

export { scrollStyleExtention, activeClass, inactiveClass }
