import { PayloadTypes } from '@/components/types'
import { useProject } from '@/composables'
import { NAVIGATION_BACK } from '@/constants'
import { WHATSAPP_SHARE } from '@/constants/pageActions'
import { productPageService } from '@/graphql'
import { useFetchAsPromise } from '@/graphql/composables/useFetch'
import { useRoute, useRouter } from 'vue-router'

export default (): {
  go: (
    button: {
      payload: string
      payloadType: PayloadTypes
    },
    query?: Record<string, string>,
  ) => void
  redirect: (url: string) => void
} => {
  const { push, back } = useRouter()
  const route = useRoute()
  const { state: project } = useProject()

  const redirect = (url: string) => {
    window.location.href = url
  }

  const internal = (payload: string) =>
    payload && push(`/page/${payload}`).then(() => scrollTo(0, 0))

  const external = (link: string) => {
    let url = link
    if (
      !url.startsWith('http') &&
      !url.startsWith('https') &&
      !url.startsWith('tel')
    ) {
      url = `https://${link}`
    }

    window.open(url, '_blank')?.focus()
  }

  const pageAction = (action: string) => {
    switch (action) {
      case NAVIGATION_BACK:
        back()
        break
      case WHATSAPP_SHARE:
        window
          .open(
            'https://api.whatsapp.com/send?text=' +
              encodeURIComponent(
                'https://' + window.location.host + route.fullPath,
              ),
            '_blank',
          )
          ?.focus()
        break
    }
  }

  const extension = (payload: string, query?: Record<string, string>) =>
    push({
      path: `/extension/${payload}`.replace(
        '/extension/BuyConfirm',
        '/extension/Buy/BuyConfirm',
      ),
      query,
    }).then(() => scrollTo(0, 0))

  const extensionToPage = async (payload: string) => {
    const data = await useFetchAsPromise(() =>
      productPageService.getProductPageIdByPageName(payload, project.productId),
    )

    push(`/page/${data.result.getProductPageIdByPageName._id}`).then(() =>
      scrollTo(0, 0),
    )
  }

  const go = (
    button: {
      payload: string
      payloadType: PayloadTypes
    },
    query?: Record<string, string>,
  ) => {
    switch (button.payloadType) {
      case 'INTERNAL':
        internal(button.payload)
        break
      case 'EXTERNAL':
        external(button.payload)
        break
      case 'PAGE_ACTION':
        pageAction(button.payload)
        break
      case 'EXTENSION':
        extension(button.payload, query)
        break
      case 'EXTENSION_TO_PAGE':
        extensionToPage(button.payload)
        break
    }
  }

  return { go, redirect }
}
