import { createApp, h } from 'vue'
import { init as amplitudeInit } from '@amplitude/analytics-browser'
import PrimeVue from 'primevue/config'
import 'primevue/resources/themes/md-light-indigo/theme.css'

import App from '@/App.vue'
import components from '@/components'
import layout from '@/layout'
import router from '@/router'

import { useProject, useUser, useLogger } from '@/composables'
import { ERROR_PAGE } from './constants'
import { initExtensionRoutes } from '@/router/index'

const app = createApp({
  render: () => h(App),
})
  .use(PrimeVue)
  .use(components)
  .use(layout)

const buildApp = async () => {
  const { initialize: initializeProject } = useProject()
  const { initialize: initializeUser } = useUser()
  const { initialize: initializeLogging } = useLogger()

  if (process.env.VUE_APP_AMPLITUDE_API_KEY) {
    amplitudeInit(process.env.VUE_APP_AMPLITUDE_API_KEY)
  }

  initializeProject().then(async (project) => {
    const $router = router(project.domain.base)
    // Importing extension routes for current project
    await initExtensionRoutes(project.projectName)
    $router.beforeEach((to, _from, next) => {
      useLogger().sendPageview(to)
      next()
    })

    Promise.all([
      initializeUser(),
      initializeLogging(),
    ])
      .then(async () => {
        app.use($router).mount('#app')
      })
      .catch(() => $router.push(ERROR_PAGE))
  })
}

buildApp()
