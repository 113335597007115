import { useFetch, UseFetch } from '../composables/useFetch'
import { GetOfferQueryResponse, GetOffersQueryResponse } from './models'
import { OFFER, OFFERS } from './queries'

export default {
  getOfferById: (
    offerId: string,
    projectId: string,
    language: string,
  ): UseFetch<GetOfferQueryResponse> =>
    useFetch<GetOfferQueryResponse>(
      OFFER,
      { offerId, projectId, language },
      {
        fetchPolicy: 'cache-and-network',
      },
    ),
  getOffersByIds: (
    offerIds: string[],
    projectId: string,
    language: string,
  ): UseFetch<GetOffersQueryResponse> =>
    useFetch<GetOffersQueryResponse>(
      OFFERS,
      { offerIds, projectId, language },
      {
        fetchPolicy: 'cache-and-network',
      },
    ),
}
