import customOrigins from '@/constants/customOrigins'
import { productService } from '@/graphql'
import { useFetchAsPromise } from '@/graphql/composables/useFetch'
import { computed, ComputedRef, reactive } from 'vue'

interface State {
  projectId: string
  productId: string
  projectName: string
  config: {
    logoImage: string
    projectBackgroundImage: string
    appDownload: {
      appStore: string
      googlePlay: string
    }
    branding: {
      font: string
      boldFont: string
      ctaTextColor: string
      iconColor: string
      ctaColor: string
      brandColor: string
      cardDesign: string
      funnelOnly: boolean
    }
  } | null
  initialized: boolean
  domain: { origin: string; base: string }
  homepageId: string
  defaultLanguage: string
  languages: string[]
  archived: boolean
  tosPageId: string
  tosLink: string
  ppLink: string
}

interface UseProject {
  state: State
  rawBase: ComputedRef<string>
  initialize: () => Promise<State>
}

const ProjectService = (): UseProject => {
  const state: State = reactive({
    projectId: '',
    productId: '',
    projectName: '',
    config: null,
    initialized: false,
    domain: { origin: '', base: '/' },
    homepageId: '',
    defaultLanguage: '',
    languages: [],
    archived: false,
    tosPageId: '',
    tosLink: '',
    ppLink: '',
  })

  const rawBase: ComputedRef<string> = computed(() =>
    state.domain.base === '/' ? '' : state.domain.base,
  )

  const initialize = async () => {
    state.domain.origin = window.location.origin
    const hasCustomDomain = Object.keys(customOrigins).includes(
      state.domain.origin,
    )
    if (hasCustomDomain) state.domain = customOrigins[state.domain.origin]

    const {
      result: { productConfig },
    } = await useFetchAsPromise(() =>
      productService.getConfig(state.domain.origin),
    )

    if (!productConfig.projectName) return Promise.reject('No project name')

    state.projectId = productConfig.projectId
    state.productId = productConfig.productId
    state.projectName = productConfig.projectName
    state.homepageId = productConfig.homepageId
    state.defaultLanguage = productConfig.defaultLanguage
    state.languages = productConfig.languages
    state.archived = productConfig.archived
    state.tosPageId = productConfig.tosPageId
    state.tosLink = productConfig.tosLink
    state.ppLink = productConfig.ppLink

    state.config = {
      branding: productConfig.branding,
      logoImage: productConfig.meta.logoImage,
      projectBackgroundImage: productConfig.meta.projectBackgroundImage,
      appDownload: {
        appStore: productConfig.meta.appStoreLink,
        googlePlay: productConfig.meta.googlePlayLink,
      },
    }

    state.initialized = true

    return state
  }

  return { state, rawBase, initialize }
}

const instance = ProjectService()

export default (): UseProject => instance
