import Mustache from 'mustache'

export interface Translation {
  [key: string]: {
    [key: string]: string
  }
}

const useTranslations = (translations: Translation, language: string) => {
  return (key: string, data?: Record<string, string>): string => {
    try {
      if (data) {
        return Mustache.render(translations[key][language], data)
      }

      return translations[key][language]
    } catch {
      return `'${key}' not found`
    }
  }
}

export default useTranslations
