import gql from 'graphql-tag'

export const OFFER = gql`
  query Offer(
    $offerId: String!
    $projectId: MongoID!
    $context: String
    $language: String
  ) {
    offer(
      offerId: $offerId
      projectId: $projectId
      context: $context
      language: $language
    ) {
      _id
      buttons {
        _id
        name
        payload
        payloadType
        appearance
      }
      title
      description
      imageUrl
      offerId
      offerPrice
      offerCurrency
    }
  }
`

export const OFFERS = gql`
  query Offers(
    $offerIds: [String]!
    $projectId: MongoID!
    $context: String
    $language: String
  ) {
    offers(
      offerIds: $offerIds
      projectId: $projectId
      context: $context
      language: $language
    ) {
      _id
      title
      description
      imageUrl
      offerId
      offerPrice
      offerCurrency
      buttons {
        _id
        name
        params
        payload
        payloadType
        appearance
        icon
      }
    }
  }
`
