import { style } from 'typestyle'
import { ffWhite } from '@/styles/constants/colors'

export const cardTextStyleNew = style({
  background: 'transparent',
  display: 'flex',
  flexDirection: 'column',
  color: ffWhite,
})

export const tableContainer = style({
  padding: '8px 16px 100px 16px',
})
