import { important, percent, px } from 'csx'
import { classes, style } from 'typestyle'
import { Property } from 'csstype'
import { makeTypographyStyle, MarginsStyle, markdownStyle } from '@/styles/common'

export const contentTypeMap = {
  default: makeTypographyStyle({}),
  content1: makeTypographyStyle({
    fontSize: px(16),
    lineHeight: px(21),
    margin: MarginsStyle.VS6,
  }),
  content2: makeTypographyStyle({
    fontSize: px(14),
    lineHeight: px(16),
    margin: MarginsStyle.VS7,
  }),
  content2New: makeTypographyStyle({
    fontSize: px(24),
    lineHeight: px(30),
    margin: MarginsStyle.VS9,
  }),
  content3: makeTypographyStyle({
    fontSize: px(12),
    lineHeight: px(16),
    margin: MarginsStyle.VS8,
  }),
  content4New: makeTypographyStyle({
    fontSize: px(14),
    lineHeight: px(20),
    margin: MarginsStyle.VS0,
  }),
  content4E2e: makeTypographyStyle({
    fontSize: px(14),
    lineHeight: px(20),
    margin: MarginsStyle.VS0,
    color: 'rgba(0, 0, 0, 0.50)',
  }),
  content6E2e: makeTypographyStyle({
    fontSize: px(14),
    lineHeight: px(20),
    margin: MarginsStyle.VS0,
    $nest: {
      p: {
        margin: '0 0 0 10px !important',
      },
      '&:before': {
        position: 'absolute',
        left: '0',
        content: '"•"',
      },
    },
  }),
  smallText: makeTypographyStyle({
    fontSize: px(10),
    lineHeight: px(14),
    margin: MarginsStyle.VS9,
  }),
  smallTextModal: makeTypographyStyle({
    fontSize: px(14),
    lineHeight: px(18),
    margin: MarginsStyle.VS8,
  }),
  subtitle: makeTypographyStyle({
    fontSize: px(14),
    lineHeight: px(16),
    marginTop: '0px',
    marginBottom: '20px',
  }),
}

export type ContentType = keyof typeof contentTypeMap

export const contentStyle = (
  contentType: ContentType,
  fontWeight: Property.FontWeight,
  textAlign: Property.TextAlign,
  color?: string,
  cardDesign?: string
): string =>
  classes(
    contentTypeMap[contentType],
    markdownStyle,
    style({
      color,
      fontWeight,
      textAlign: textAlign as any,
      width: percent(100),
      $nest: {
        p: {
          margin: cardDesign === 'e2eCard' ? '0 0 0 10px !important ' : important(0),
        },
      },
    })
  )

export const containerStyle = style({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

export const iconStyle = style({
  $nest: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
})
