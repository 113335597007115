
import { useMessenger, useTranslations, useUser } from '@/composables'
import { styleContainer } from '@/styles/container'
import { FormItem } from '@/models/form'
import { defineComponent, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import translations from './translations'
import TermsAndConditions from '../../Components/TermsAndConditions'
import { AxiosError } from 'axios'
import { ChannelId } from '@/models/channels'

interface FormEventResponse {
  invalid: boolean
  lockout: boolean
  expired?: boolean
}

export default defineComponent({
  name: 'Login',
  components: { TermsAndConditions: TermsAndConditions },
  setup: () => {
    let showPinInput = ref(false)
    let buttonDisabled = ref(true)
    let currentNumber = ''
    const { state: user, setCustomerUserId } = useUser()
    const { push } = useRouter()
    const t = useTranslations(translations, user.language)
    const { sendMessengerPayload, closeWebview } = useMessenger()
    const route = useRoute()

    const redirectURL = route.query?.redirect?.toString() || '/'
    const resendText = {
      question: t('resendQuestion'),
      resend: t('resend'),
    }

    const pinErrors = [
      {
        id: 'lockout',
        message: t('pinErrorLockoutMessage'),
        value: false,
        modalTitle: t('pinErrorLockoutMessage'),
        modalSubtitle: t('pinErrorSubtitle'),
      },
      {
        id: 'invalid',
        message: t('pinErrorInvalidMessage'),
        value: false,
        modalTitle: t('pinErrorInvalidMessage'),
        modalSubtitle: t('pinErrorSubtitle'),
      },
      {
        id: 'expired',
        message: t('pinErrorExpiredMessage'),
        value: false,
        modalTitle: t('pinErrorExpiredMessage'),
        modalSubtitle: t('pinErrorSubtitle'),
      },
    ]

    const errors = [
      {
        id: 'lockout',
        message: t('numberErrorLockoutMessage'),
        value: false,
        modalTitle: t('numberErrorLockoutMessage'),
        modalSubtitle: t('pinErrorSubtitle'),
      },
      {
        id: 'invalid',
        message: t('numberErrorInvalidMessage'),
        value: false,
        modalTitle: t('numberErrorInvalidTitle'),
        modalSubtitle: t('numberErrorInvalidSubtitle'),
      },
    ]

    const info = {
      modalTitle: t('modalTitle'),
      modalSubtitle: t('modalSubtitle'),
    }

    const phoneCallback = async (value: FormItem<string>) => {
      let response: FormEventResponse = {
        invalid: false,
        lockout: false,
      }
      currentNumber = value.value

      return await useUser()
        .pinRegistration(value.value)
        .then(() => {
          return Promise.resolve(response)
        })
        .catch((err: AxiosError) => {
          const status = err.response?.status
          if (status === 429) {
            response.lockout = true
            response.invalid = true
          } else {
            response.invalid = true
          }
          return Promise.resolve(response)
        })
    }

    const pinCallback = async (value: FormItem<string>) => {
      let response: FormEventResponse = {
        invalid: false,
        lockout: false,
        expired: false,
      }
      return await useUser()
        .confirmRegistration('pin', value.value)
        .then(() => {
          return Promise.resolve(response)
        })
        .catch((err: AxiosError) => {
          const status = err.response?.status
          if (status === 429) {
            response.lockout = true
            response.invalid = true
          } else if (status === 408) {
            response.expired = true
            response.invalid = false
          } else {
            response.invalid = true
          }
          return Promise.resolve(response)
        })
    }

    const resendPin = () => {
      phoneCallback({
        value: currentNumber,
        formItemId: 'numberInput',
        isRequired: true,
      })
    }

    const handleClick = () => {
      setCustomerUserId(currentNumber)

      if (user.channelId === ChannelId.FacebookMessenger) {
        sendMessengerPayload(route)
        closeWebview()

        return
      }

      push(redirectURL)
    }

    const formCheck = (event: FormItem) => {
      if (event.formItemId === 'numberInput') {
        if (event.valid) {
          showPinInput.value = true
        }
      } else if (event.formItemId === 'pinInput') {
        if (event.valid) {
          buttonDisabled.value = false
        }
      }
    }

    return {
      pinErrors,
      errors,
      resendText,
      resendPin,
      handleClick,
      styleContainer,
      phoneCallback,
      pinCallback,
      info,
      user,
      formCheck,
      showPinInput,
      buttonDisabled,
      t,
    }
  },
})
