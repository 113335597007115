export const PAGE_VIEW = 'PAGE_VIEW'
export const CLICK = 'CLICK'
export const MENU_CLICK = 'MENU_CLICK'
export const HEADER_CLICK = 'HEADER_CLICK'
export const HEADER_ENRICHMENT_SUCCESS = 'HEADER_ENRICHMENT_SUCCESS'
export const HEADER_ENRICHMENT_FAILURE = 'HEADER_ENRICHMENT_FAILURE'
export const HEADER_ENRICHMENT_ATTEMPT = 'HEADER_ENRICHMENT_ATTEMPT'

export type Action =
  | typeof PAGE_VIEW
  | typeof CLICK
  | typeof MENU_CLICK
  | typeof HEADER_CLICK
  | typeof HEADER_ENRICHMENT_SUCCESS
  | typeof HEADER_ENRICHMENT_FAILURE
  | typeof HEADER_ENRICHMENT_ATTEMPT

export interface TrackingInfo {
  version: number
  projectId: string
  pageId: string
  pageName?: string
  pageRoute: string
  channelId: string
  channelUserId?: string
  language: string
  sessionId: string
  customFields?: Record<string, any>
}
