export default {
  title: {
    en: 'Sign-in with your number',
    ka: 'სისტემაში შესვლა მობილური ნომრით',
  },
  subtitle: {
    en: 'We will send you the 4 digit verification code',
    ka: 'ჩვენ გამოგიგზავნით 4-ციფრიან დამადასტურებელ კოდს',
  },
  modalTitle: {
    en: 'Entering your number is a 1-time process only.',
    ka: 'ნომრის შეყვანა დაგჭირდება მხოლოდ ერთხელ.',
  },
  modalSubtitle: {
    en: 'We will need your number to connect to your account.',
    ka: 'ამით ჩვენ შევძლებთ დავუკავშირდეთ შენი ნომრის ანგარიშს.',
  },
  phoneNumberHint: {
    en: 'Phone number',
    ka: 'მობილურის ნომერი',
  },
  confirm: {
    en: 'Confirm',
    ka: 'დადასტურება',
  },
  enterPin: {
    en: 'Please enter the PIN to confirm',
    ka: 'გთხოვთ, შეიყვანოთ PIN დასადასტურებლად',
  },
  resendQuestion: {
    en: `Didn't receive PIN?`,
    ka: 'არ მიგიღია კოდი?',
  },
  resend: {
    en: 'Resend',
    ka: 'თავიდან გაგზავნა',
  },
  pinErrorLockoutMessage: {
    en: 'Timeout, please try entering your PIN code later.',
    ka: 'მოხდა შეცდომა. გთხოვ, შეიყვანე შენი PIN კოდი თავიდან.',
  },
  pinErrorInvalidMessage: {
    en: 'The PIN code you entered is incorrect.',
    ka: 'შეყვანილი PIN კოდი არასწორია.',
  },
  pinErrorExpiredMessage: {
    en: 'Your PIN code has expired. Click Resend PIN to receive a new one',
    ka: 'შენს PIN კოდს ვადა ამოეწურა. დააჭირე PIN-ის ხელახლა გაგზავნას, რათა მიიღო ახალი კოდი',
  },
  pinErrorSubtitle: {
    en: 'We will need your confirmation PIN to connect to your account.',
    ka: 'შენს ანგარიშთან დასაკავშირებლად, დაგვჭირდება PIN კოდი.',
  },
  numberErrorLockoutMessage: {
    en: '*You entered an invalid number too many times.',
    ka: '* შენ ბევრჯერ შეიყვანე არასწორი ტელეფონის ნომერი',
  },
  numberErrorInvalidMessage: {
    en: '*Please provide a valid phone number.',
    ka: 'გთხოვ, შეიყვანე სწორი ტელეფონის ნომერი',
  },
  numberErrorInvalidTitle: {
    en: 'Please enter a valid phone number.',
    ka: 'გთხოვთ, შეიყვანოთ სწორი ტელეფონის ნომერი.',
  },
  numberErrorInvalidSubtitle: {
    en: 'We will need your number to connect to your account.',
    ka: 'ამით ჩვენ შევძლებთ დავუკავშირდეთ შენი ნომრის ანგარიშს.',
  },
}
