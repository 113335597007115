import { ffRed } from '@/styles/constants/colors'
import { style } from 'typestyle'

export const container = style({
  marginTop: '16px',
})

export const field = style({
  width: '100%',
})

export const errorText = style({
  color: ffRed,
})
