import { style } from 'typestyle'

export const pageContainer = style({
  padding: '0 24px'
})

export const title = style({
  margin: '20px 0',
  fontSize: '20px',
  fontWeight: 'bold',
})

export const description = style({
  fontSize: '14px',
  paddingBottom: '20px',
})
