import { style, classes } from 'typestyle'
import { px, important } from 'csx'

import { contentTypeMap } from './Content'

export const centerItems = style({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  width: px(285),
  marginLeft: 'auto',
  marginRight: 'auto',
})

export const inputContainerStyle = style({
  width: px(285),
  height: px(36),
  margin: px(8),
  display: 'flex',
  flexDirection: 'row',
})

export const getPrefixStyle = classes(
  style({
    borderBottom: `${px(1)} ${'solid'} ${'darkgrey'}`,
    width: px(60),
    border: 'none',
    background: 'transparent',
    outline: 'none',
    padding: `${px(10)} ${0}`,
    color: '#000000',
    textAlign: 'center',
    lineHeight: important('normal'),
    margin: important(px(0)),
  }),
  contentTypeMap['content2'],
)

export const inputStyle = (error: boolean, prefix?: boolean): string => {
  const inputStyle = style({
    border: 'none',
    background: 'transparent',
    borderBottom: `${px(1)} ${'solid'} ${'darkgrey'}`,
    margin: prefix ? important(`${px(0)} ${px(0)} ${px(0)} ${px(8)}`) : 0,
    width: prefix ? px(217) : px(285),
    outline: 'none',
    lineHeight: important('normal'),
    padding: `${px(2)} ${px(0)} ${px(0)} ${px(10)}`,
    color: error ? important('#ED2C2C') : important('#060606'),
    textAlign: prefix ? 'left' : 'center',
    $nest: {
      '::placeholder': {
        color: '#808080',
      },
      ':focus': {
        outline: 'none',
      },
      ':focus::placeholder': {
        color: 'transparent',
      },
    },
  })
  return classes(inputStyle, contentTypeMap['content2'])
}

export const iconStyle = style({
  paddingTop: px(9),
  borderBottom: `${px(1)} ${'solid'} ${'darkgrey'}`,
})

export const timeoutStyle = style({
  paddingLeft: px(10),
  marginLeft: px(10),
  borderBottom: `${px(1)} ${'solid'} ${'darkgrey'}`,
})

export const errorMessageStyle = style({
  margin: 'auto',
  width: px(285),
  $nest: {
    span: {
      $nest: {
        p: {
          margin: 0,
        },
      },
    },
  },
})
