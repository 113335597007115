export default {
  title: {
    en: 'Entering your number is a 1-time process only.',
    ka: 'ნომრის შეყვანა დაგჭირდება მხოლოდ ერთხელ.',
  },
  subtitle: {
    en: 'We will need your number to connect to your account.',
    ka: 'ამით ჩვენ შევძლებთ დავუკავშირდეთ შენი ნომრის ანგარიშს.',
  },
  signOut: {
    en: 'Sign Out',
    ka: 'სისტემიდან გამოსვლა',
  },
}
