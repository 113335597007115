import { reactive } from 'vue'
import { startTopup, getTopResult } from './topup.service'
import { startTopupRequest } from '@/models/api/topup'
import { AxiosError, AxiosResponse } from 'axios'
interface State {
  amount?: string
  transactionId?: string
  timestamp?: string
  recipient?: string
  currency?: string
}

interface UseTopup {
  state: State
  initTopup: (payload: startTopupRequest) => Promise<AxiosResponse>
  fetchTopResult: (
    trans_id: string,
  ) => Promise<{ data?: State; status: number }>
}

const state: State = reactive({})

const useTopup = (amount?: string): UseTopup => {
  // Initialize state
  state.amount = amount

  const initTopup = async (payload: startTopupRequest) => {
    const response = await startTopup(payload)
    return response
  }

  const fetchTopResult = async (trans_id: string) => {
    try {
      const response: AxiosResponse<State> = await getTopResult(trans_id)
      const { amount, currency, recipient, timestamp, transactionId } =
        response.data
      state.amount = amount
      state.currency = currency
      state.recipient = recipient
      state.timestamp = timestamp
      state.transactionId = transactionId
      return { data: response.data, status: 200 }
    } catch (error) {
      const typedError = error as AxiosError
      return { status: typedError.response?.status || 500 }
    }
  }
  return { state: state, initTopup, fetchTopResult }
}

export default useTopup
