import { useFetch, UseFetch } from '../composables/useFetch'
import {
  GetProductConfigResponse,
  GetProductHeaderMenuQueryResponse,
} from './models'
import { PRODUCT_CONFIG, PRODUCT_HEADER_MENU } from './queries'

export default {
  getConfig: (
    domainName: string,
    language?: string,
  ): UseFetch<GetProductConfigResponse> =>
    useFetch<GetProductConfigResponse>(
      PRODUCT_CONFIG,
      { domainName, language },
      {
        fetchPolicy: 'cache-and-network',
      },
    ),
  getProductHeaderMenu: (
    productId: string,
    language: string,
  ): UseFetch<GetProductHeaderMenuQueryResponse> =>
    useFetch<GetProductHeaderMenuQueryResponse>(
      PRODUCT_HEADER_MENU,
      { productId, language },
      {
        fetchPolicy: 'cache-and-network',
      },
    ),
}
