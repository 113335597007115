import { style } from 'typestyle'
import { percent, px } from 'csx'

const cardStyle = style({
  borderRadius: px(20),
  boxShadow: '0px 1px 10px 0px rgb(0 0 0 / 10%)',
  display: 'flex',
  flexDirection: 'column',
  height: percent(100),
  overflow: 'hidden',
  width: px(249),
  margin: px(4),
  marginLeft: 'auto',
  marginRight: 'auto',
  padding: '12px 18px 12px 18px',
})

const bulletStyle = style({
  $nest: {
    '& p': {
      marginBottom: '5px',
      $nest: {
        '& p': {
          fontWeight: 'bold',
        },
      },
    },
  },
})
export { cardStyle, bulletStyle }
