import { reactive } from 'vue'

interface State {
  languages: string[]
}

interface UseCampaign {
  state: State
}

const CampaignService = (): UseCampaign => {
  const state: State = reactive({
    languages: [],
  })

  return { state }
}

const instance = CampaignService()

export default (): UseCampaign => instance
