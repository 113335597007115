import { important, px } from 'csx'
import { classes, style } from 'typestyle'
import { horizontallyCenterChildren } from 'csstips'
import { ffWhite, ffGrey, whatsapp, ffBlack, ffDisabled } from '../../styles/constants/colors'

import injectStyle from '@/helpers/injectStyle'

export type ButtonApparance = 'outline' | 'default' | 'whatsapp'
export type ButtonTypes =
  | 'button_card'
  | 'button_default'
  | 'button_radio'
  | 'button_option'
  | 'button_settings'
  | 'button_readmore'

export default (): {
  buttonAlignment: (type: ButtonTypes) => string
  buttonStyleExtension: (appearance: ButtonApparance, type: ButtonTypes, disabled: boolean) => string
} => {
  const styles = injectStyle()

  const roundCornersButton = (radius = `${px(20)}`) => ({
    '-webkit-border-radius': radius,
    '-moz-border-radius': radius,
    'border-radius': radius,
    '-moz-background-clip': 'padding',
    '-webkit-background-clip': 'padding-box',
    'background-clip': 'padding-box',
  })

  const buttonBase = {
    ...roundCornersButton(),
    ...horizontallyCenterChildren,
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
  }

  // Button Type Styles
  const buttonPrimaryBase = {
    ...buttonBase,
    margin: px(4),
    fontSize: px(16),
    width: px(283),
    height: px(43),
    $nest: {
      svg: {
        width: px(32),
        height: px(32),
        marginRight: px(8),
      },
    },
    $debugName: 'btn-primary',
  }

  const buttonOptionBase = {
    background: important(ffWhite),
    color: important(ffBlack),
    border: 'none',
    width: px(285),
    padding: '11px 0px 11px 0px',
    fontSize: px(14),
    borderTop: `1px solid ${ffGrey}`,
    borderBottom: `1px solid transparent`,
    justifyContent: important('left'),
    $nest: {
      img: {
        width: px(22),
        height: px(22),
        marginRight: px(8),
      },
      svg: {
        width: px(22),
        height: px(22),
        marginRight: px(8),
        viewBox: '0 0 22 22',
      },
    },
    $debugName: 'btn-option',
  }

  const typeMap = {
    button_card: style({
      ...buttonPrimaryBase,
      width: styles.cardDesign === 'newCard' ? px(300) : styles.cardDesign === 'e2eCard' ? '100%' : px(235),
      height: styles.cardDesign === 'newCard' ? px(45.426) : styles.cardDesign === 'e2eCard' ? px(41) : px(35),
      fontSize: px(15),
      margin: styles.cardDesign === 'e2eCard' ? `${px(4)} 0` : `${px(6)} 0`,
      $nest: {
        svg: {
          width: px(18),
          height: px(18),
          marginRight: px(8),
        },
      },
      $debugName: 'btn-card',
    }),
    button_readmore: style({
      ...buttonPrimaryBase,
      width: styles.cardDesign === 'newCard' ? px(300) : styles.cardDesign === 'e2eCard' ? '100%' : px(235),
      height: styles.cardDesign === 'newCard' ? px(45.426) : styles.cardDesign === 'e2eCard' ? px(41) : px(35),
      fontSize: px(15),
      margin: styles.cardDesign === 'e2eCard' ? `${px(4)} 0` : `${px(6)} 0`,
      $nest: {
        svg: {
          width: px(18),
          height: px(18),
          marginRight: px(8),
        },
      },
      $debugName: 'btn-card',
    }),
    button_default: style(buttonPrimaryBase),
    button_radio: style({
      ...buttonBase,
      padding: px(9),
      fontSize: px(14),
      $debugName: 'btn-radio',
    }),
    button_option: style(buttonOptionBase),
    button_settings: style({
      width: 'auto',
      padding: '10px 27px',
      height: '38px',
      border: '1px solid #000B30E5',
      background: 'transparent',
      fontSize: '14px',
      borderRadius: '20px',
    }),
  }

  const appearenceMap = {
    outline: style({
      background: styles.cardDesign === 'newCard' || styles.cardDesign === 'e2eCard' ? 'transparent' : `${ffWhite}`,
      color: styles.cardDesign === 'newCard' || styles.cardDesign === 'e2eCard' ? `${ffWhite}` : `${ffBlack}`,
      border:
        styles.cardDesign === 'newCard' || styles.cardDesign === 'e2eCard'
          ? `1px solid ${ffWhite}`
          : `1px solid ${ffGrey}`,

      cursor: 'pointer',
      $debugName: 'btn-outline',
    }),
    whatsapp: style({
      background: whatsapp,
      color: ffWhite,
      border: `1px solid transparent`,
      cursor: 'pointer',
      $debugName: 'btn-whatsapp',
    }),
    default: style({
      background: styles?.ctaColor,
      border: `1px solid transparent`,
      color: styles?.ctaTextColor,
      cursor: 'pointer',
      $debugName: 'btn-default',
    }),
  }

  // disabled style
  const buttonDisabled = (appearance: ButtonApparance) => {
    switch (appearance) {
      case 'outline':
        return style({
          cursor: 'default',
          color: ffGrey,
          background: ffWhite,
          $debugName: 'btn-outline-disabled',
        })
      case 'default':
      default:
        return style({
          cursor: 'default',
          color: ffWhite,
          background: ffDisabled,
          $debugName: 'btn-default-disabled',
        })
    }
  }

  // container for alignment
  const buttonAlignment = (type: ButtonTypes) => {
    switch (type) {
      case 'button_settings':
        return style({
          display: 'block',
        })
      case 'button_readmore':
        return style({
          display: 'flex',
          justifyContent: 'center',
          margin: styles.cardDesign === 'e2eCard' ? '0 38px' : 'unset',
        })

      default:
        return style({
          display: 'flex',
          justifyContent: 'center',
        })
    }
  }

  const buttonStyleExtension = (appearance: ButtonApparance, type: ButtonTypes, disabled: boolean): string =>
    classes(
      appearenceMap[appearance],
      typeMap[type],
      disabled && buttonDisabled(appearance),
      style({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        $debugName: 'btn_base',
      })
    )

  return { buttonAlignment, buttonStyleExtension }
}
