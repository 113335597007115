import { style } from 'typestyle'

export const container = style({
  margin: '32px 52px 48px 52px',
  textAlign: 'center',
})

export const containerE2e = style({
  margin: '32px 0 42px 28px !important',
  textAlign: 'start',
})

export const title = style({
  fontSize: '24px',
  fontWeight: 'bold',
})

export const subtitle = style({
  marginTop: '12px',
  fontSize: '14px',
})
