import axios, { AxiosResponse } from 'axios'
// import { publicIp } from 'public-ip';
import { startTopupRequest } from '@/models/api/topup'
import platformProxy from '@/utils/platformProxy'

export const startTopup = async (
  payload: startTopupRequest,
): Promise<AxiosResponse> =>
  await axios.post(platformProxy(`topup/start`), payload)

export const getTopResult = async (trans_id: string): Promise<AxiosResponse> =>
  await axios.post(platformProxy(`topup/result`), { trans_id })
