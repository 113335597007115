let snapRemoved = false

export function disableBodyScrolling() {
  document.body.classList.add('modal-open')

  if (document.documentElement.classList.contains('snap-enabled')) {
    document.documentElement.classList.remove('snap-enabled')
    document.body.classList.remove('snap-enabled')

    snapRemoved = true
  }
}

export function enableBodyScrolling() {
  document.body.classList.remove('modal-open')

  if (snapRemoved) {
    document.documentElement.classList.add('snap-enabled')
    document.body.classList.add('snap-enabled')
  }
}
