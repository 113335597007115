export default {
  title: {
    en: 'Your Balances',
    ka: 'შენი ბალანსები',
  },
  updatedOn: {
    en: 'Updated on ',
    ka: 'განახლებულია ',
  },
  personalOffer: {
    en: 'Personal Offer',
    ka: 'შეთავაზება შენთვის',
  },
  findPackages: {
    en: 'Find Packages',
    ka: 'ყველა შეთავაზება',
  },
  balances: {
    en: 'Balances:',
    ka: 'ბალანსი:',
  },
  dataBalances: {
    en: 'Data Balances:',
    ka: 'ინტერნეტ ბალანსი:',
  },
  voiceBalances: {
    en: 'Voice Balances:',
    ka: 'ხმოვანი ბალანსი:',
  },
  smsBalances: {
    en: 'SMS Balances:',
    ka: 'SMS ბალანსი:',
  },
  at: {
    en: ' at ',
    ka: ' ზე ',
  },
  packName: {
    en: 'Pack Name:',
    ka: 'პაკეტის სახელი:',
  },
  mainBalance: {
    en: 'Main Balance',
    ka: 'ძირითადი ბალანსი',
  },
}
