import platformProxy from '@/utils/platformProxy'
import {
  ApolloClient,
  InMemoryCache,
  from,
  NormalizedCacheObject,
} from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { ErrorResponse, onError } from '@apollo/client/link/error'

import { createUploadLink } from 'apollo-upload-client'

export default (): ApolloClient<NormalizedCacheObject> => {
  const httpLink = createUploadLink({
    uri: platformProxy(`graphql`),
  })

  const authLink = setContext((_, { headers }) => ({
    headers,
  }))

  const errorHandler = onError(({ networkError }: ErrorResponse) => {
    console.log('networkError', networkError)
  })

  return new ApolloClient({
    link: from([errorHandler, authLink.concat(httpLink)]),
    cache: new InMemoryCache(),
    connectToDevTools: true,
  })
}
