import gql from 'graphql-tag'

export const PRODUCT_CONFIG = gql`
  query ProductConfig($domainName: String) {
    productConfig(domainName: $domainName) {
      defaultLanguage
      projectName
      meta {
        logoImage
        projectBackgroundImage
        appStoreLink
        googlePlayLink
      }
      branding {
        font
        boldFont
        ctaTextColor
        iconColor
        ctaColor
        brandColor
        cardDesign
        funnelOnly
      }
      projectId
      productId
      homepageId
      languages
      archived
      tosPageId
      tosLink
      ppLink
    }
  }
`

export const PRODUCT_HEADER_MENU = gql`
  query ProductHeaderAndMenuTranslated(
    $productId: MongoID!
    $language: String
    $context: String
  ) {
    productHeaderAndMenuTranslated(
      productId: $productId
      language: $language
      context: $context
    ) {
      menu {
        icon
        iconActive
        name
        payload
      }
      header {
        icon
        payload
        homepagePayload
      }
    }
  }
`
