import { style } from 'typestyle'
import { px } from 'csx'
import { ffLightGrey } from '../../styles/constants/colors'

export const rowStyle = style({
  background: ffLightGrey,
  display: 'flex',
  flexDirection: 'column',
  padding: `${px(20)} ${px(12)}`,
  margin: `${px(4)} ${0}`,
  $nest: { '*': { margin: 0 } },
})

export const titleStyle = style({
  margin: '0 0 8px 0',
})
