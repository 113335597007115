export default {
  title: {
    en: 'Top-up Failed',
    ka: 'unspecified',
  },
  findPackages: {
    en: 'Find Packages',
    ka: 'unspecified',
  },
  checkBalance: {
    en: 'Check Balances',
    ka: 'unspecified',
  },
  dateTime: {
    en: 'Date and time',
    ka: 'unspecified',
  },
  topupMade: {
    en: 'Top up for {{ number }} has failed',
    ka: 'unspecified {{ number }}',
  },
}
