import gql from 'graphql-tag'

export const PRODUCT_PAGE_QUERY = gql`
  query ProductPageTranslated(
    $id: MongoID
    $productId: MongoID
    $language: String
    $context: String
  ) {
    productPageTranslated(
      id: $id
      productId: $productId
      language: $language
      context: $context
    ) {
      pageDetails {
        ... on CarouselPageTranslated {
          title
          description
          cards {
            _id
            title
            description
            imageUrl
            buttons {
              _id
              name
              params
              payload
              payloadType
              appearance
            }
          }
        }
        ... on ErrorPageTranslated {
          title
          description
          subtitle
          iconName
          buttons {
            _id
            name
            payload
            payloadType
            appearance
          }
        }
        ... on LearnMorePageTranslated {
          title
          description
          buttons {
            _id
            name
            payload
            payloadType
            appearance
          }
        }
        ... on ReadMorePageTranslated {
          title
          readMoreRows {
            subtitle
            description
          }
          buttons {
            _id
            name
            payload
            payloadType
            appearance
          }
        }
        ... on SettingsPageTranslated {
          languageSwitch {
            popupIcon
            popupText
            buttons {
              value
              text
            }
          }
          options {
            _id
            name
            payload
            payloadType
            icon
            appearance
          }
          settingsPageItems {
            value
            text
          }
        }
      }
    }
  }
`

export const PRODUCT_PAGE_ID_BY_PAGE_NAME_QUERY = gql`
  query ProductPageIdByPageName(
    $name: String!
    $productId: MongoID!
    $context: String
  ) {
    getProductPageIdByPageName(
      name: $name
      productId: $productId
      context: $context
    ) {
      _id
    }
  }
`
