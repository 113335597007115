export default {
  title: {
    en: 'Top-up Successful',
    ka: 'unspecified',
  },
  findPackages: {
    en: 'Find Packages',
    ka: 'unspecified',
  },
  transactionId: {
    en: 'Transaction ID',
    ka: 'unspecified',
  },
  dateTime: {
    en: 'Date and time',
    ka: 'unspecified',
  },
  topupMade: {
    en: 'Top up was made for',
    ka: 'unspecified',
  },
  topAmount: {
    en: 'Top up amount',
    ka: 'unspecified',
  },
}
