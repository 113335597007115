import {
  BannerBoxProperties,
  ButtonApparance,
  ButtonProperties,
  ButtonTypes,
  CardProperties,
  CarouselProperties,
  ContentProperties,
  HeaderProperties,
  IconBaseProperties,
  LanguageSwitchProperties,
  PayloadTypes,
  StickyButtonsProperties,
  TableProperties,
} from '@/components/types'
import { CardListProperties } from '@/components/types/CardList'
import { IButton, ICard, ILanguageSwitch, ITableRow, ILanguageSwitchOption } from '@/graphql'
import { ffBlack } from '@/styles/constants/colors'
import { processName } from '@/utils/bucket'
import publicPath from '@/utils/publicPath'

const BACKUP_TEXT = 'Text not found'
const BACKUP_IMG = publicPath('/images/default.png')

// button cases
const isOptionButton = (type: ButtonTypes | string) => type === 'button_option'
const isPageAction = (payloadType: PayloadTypes | string) => payloadType === 'PAGE_ACTION'

// mapper functions

const buttonMapper = (btn: IButton, type: ButtonTypes): ButtonProperties => ({
  appearance: btn.appearance as ButtonApparance,
  name: btn.name || BACKUP_TEXT,
  payload: btn.payload,
  payloadType: (btn.payloadType as PayloadTypes) || 'INTERNAL',
  type,
  disabled: false,
  icon: btn.icon ? processName(btn.icon) : null,
  params: btn.params || {},
})

const processImageName = (url: string) => {
  const urlParts = url.split('/')
  const imageName = urlParts.splice(-1)[0]
  return `${processName(urlParts.join('/'))}/${imageName}`
}

const cardMapper = (card: ICard): CardProperties => {
  const result: CardProperties = {
    title: card.title || BACKUP_TEXT,
    description: card.description || BACKUP_TEXT,
    imageUrl: processImageName(card.imageUrl || BACKUP_IMG),
    apiCard: card,
  }

  if (card.type === 'leadForm') {
    const buttons = [
      {
        name: card.formButtonLabel!,
        appearance: 'default',
        payload: 'leadForm',
        payloadType: 'PAGE_ACTION',
        disabled: !!card.leadFormDisabled,
      },
    ] as ButtonProperties[]
    if (card.readMore)
      buttons.push({
        name: card.readMoreButtonLabel!,
        appearance: 'outline',
        payload: 'readMore',
        payloadType: 'PAGE_ACTION',
        type: 'button_readmore',
      })
    result.buttons = buttons
  } else if (card.type === 'trafficCard') {
    const buttons = [] as ButtonProperties[]
    if (card.ctaButtonLabel !== '') {
      buttons.push({
        name: card.ctaButtonLabel!,
        appearance: 'default',
        payload: card.ctaButtonLink,
        payloadType: card.trafficType as PayloadTypes,
        type: 'button_default',
      })
    }
    if (card.readMore)
      buttons.push({
        name: card.readMoreButtonLabel!,
        appearance: 'outline',
        payload: 'readMore',
        payloadType: 'PAGE_ACTION',
        type: 'button_readmore',
      })
    result.buttons = buttons
  } else {
    result.buttons = (card.buttons || []).map((b) => buttonMapper(b, 'button_card'))
  }

  return result
}

const cardsMapper = (cards: ICard[]): CardProperties[] => cards.map((card) => cardMapper(card))

// registered component mappers
const ffComponent = <N, P>(name: N, props: P) => ({ name, props })
const components = {
  ffBannerBox: (props: BannerBoxProperties) => ffComponent('ff-banner-box', props),
  ffButton: (props: ButtonProperties) => ffComponent('ff-button', props),
  ffCardList: (props: CardListProperties) => ffComponent('ff-card-list', props),
  ffCarousel: (props: CarouselProperties) => ffComponent('ff-carousel', props),
  ffContent: (props: ContentProperties) => ffComponent('ff-content', props),
  ffHeader: (props: HeaderProperties) => ffComponent('ff-header', props),
  ffIconBase: (rawProps: IconBaseProperties) => {
    const props: IconBaseProperties = {
      ...rawProps,
      iconUrl: rawProps.iconUrl,
    }
    return ffComponent('ff-icon-base', props)
  },
  ffStickyButtons: (props: StickyButtonsProperties) => ffComponent('ff-sticky-buttons', props),
  ffTable: (props: TableProperties) => ffComponent('ff-table', props),
  ffLanguageSwitch: (props: LanguageSwitchProperties) => ffComponent('ff-language-switch', props),
}

export type FFComponent<P = Record<string, any>> = {
  name: string
  props: P
}

// component presets
export default {
  bannerBox: (text: string): FFComponent<BannerBoxProperties> => components.ffBannerBox({ text }),
  buttons: (buttons: IButton[], type?: ButtonTypes): FFComponent<ButtonProperties>[] =>
    buttons.map((b) => components.ffButton(buttonMapper(b, (type as ButtonTypes) || 'button_default'))),
  cardList: (cards: ICard[]): FFComponent<CardListProperties> =>
    components.ffCardList({
      cards: cards && cardsMapper(cards),
    }),
  carousel: (cards: ICard[]): FFComponent<CarouselProperties> =>
    components.ffCarousel({
      cards: cards && cardsMapper(cards),
    }),
  iconLarge: (iconUrl: string): FFComponent<IconBaseProperties> =>
    components.ffIconBase({
      iconUrl,
      maxHeight: '155px',
      maxWidth: '146px',
      align: 'center',
      color: '',
      fillColor: '',
    }),
  langSwitch: (
    properties: ILanguageSwitch,
    settingsPageItems: ILanguageSwitchOption[]
  ): FFComponent<LanguageSwitchProperties> =>
    components.ffLanguageSwitch({
      confirmationIcon: properties.popupIcon,
      confirmationText: properties.popupText,
      options: settingsPageItems,
    }),
  longContent: (text: string): FFComponent<ContentProperties> =>
    components.ffContent({
      text,
      contentType: 'content2',
      fontWeight: 'normal',
      textAlign: 'left',
    }),
  stickyButtons: (buttons: IButton[]): FFComponent<StickyButtonsProperties> =>
    components.ffStickyButtons({
      buttons: buttons.map((b) => buttonMapper(b, 'button_default')),
    }),
  table: (rows: ITableRow[]): FFComponent<TableProperties> => components.ffTable({ rows }),
  header: (text: string, subtitleText?: string): FFComponent<HeaderProperties> =>
    components.ffHeader({
      text,
      subtitleText,
    }),
}
