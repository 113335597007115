/* eslint-disable @typescript-eslint/no-explicit-any */
import { Ref, watch } from 'vue'
import { useQuery, provideApolloClient } from '@vue/apollo-composable'

import { DocumentNode } from 'graphql'
import { ApolloError } from '@apollo/client/errors'
import {
  OptionsParameter,
  VariablesParameter,
} from '@vue/apollo-composable/dist/useQuery'
import apolloClient from '@/service/apolloClient'

import { PREVIEW, LIVE } from '@/constants'
import { useProject } from '@/composables'

const getContentVersion = () => {
  const { state: project } = useProject()
  // const baseUrl = window.location.host

  // Fetch LIVE content in local development
  // if (project.domain.slice(0, 9) === 'localhost') {
  //   return PREVIEW
  // }

  // Check for preview keyword in subdomain name
  const subDomain = project.domain.origin
    .replace(/^https?:\/\//, '')
    .slice(0, -11)
    .split('-')
  return subDomain.indexOf('preview') > -1 ? PREVIEW : LIVE
}

export interface UseFetch<R = any, P = any> {
  result: Readonly<Ref<Readonly<R>>>
  refetch: (params: VariablesParameter<P>) => void
  loading: Ref<boolean>
  error?: Ref<ApolloError | null>
}

export const useFetch = <R = any, P = any>(
  graphQLDoc: DocumentNode,
  params?: VariablesParameter<P>,
  options?: OptionsParameter<any, any>,
): UseFetch<R> => {
  const contentVersion = getContentVersion()
  provideApolloClient(apolloClient())
  const { result, loading, error, refetch } = useQuery(
    graphQLDoc,
    { context: contentVersion, ...params },
    options || {},
  )
  return { result, refetch, loading, error }
}

export interface UseFetchAsPromise<R, P = any> {
  result: R
  refetch: (params: P) => void
}

export const useFetchAsPromise = <R, P = any>(
  useFetchFn: () => UseFetch<R>,
): Promise<UseFetchAsPromise<R, P>> => {
  return new Promise((resolve, reject) => {
    const { loading, result, error, refetch } = useFetchFn()

    watch(loading, (loading) => {
      if (error?.value) reject(error.value)
      if (!loading) resolve({ result: result.value, refetch })
    })
  })
}
