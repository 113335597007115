import { channelUserIdCookie } from '@/constants'

import axios from 'axios'
import { reactive } from 'vue'
import { v4 as uuid } from 'uuid'
import useProject from './useProject'
import { getCookie, setCookie } from '@/utils'
import {
  ConfirmRequest,
  PinRequest,
  PinResponse,
  RegStatus,
  RegType,
} from '@/models/api/registration'
import { ChannelId } from '@/models/channels'
import { removeCookie } from '@/utils/cookies'
import platformProxy from '@/utils/platformProxy'
import { useLogger } from './'
import DeviceDetector from 'device-detector-js'

interface State {
  channelId: ChannelId
  channelUserId: string | null
  customerUserId: string | null
  language: string
  initialized: boolean
  operatingSystem: 'ios' | 'android' | 'desktop'
}
interface UseUser {
  state: State
  initialize: () => Promise<void>
  updateUserLanguage: (language: string) => Promise<void>
  pinRegistration: (customerUserId: string) => Promise<PinResponse>
  confirmRegistration: (
    type: RegType,
    pin?: string | undefined,
  ) => Promise<void>
  signOut: () => Promise<void>
  setCustomerUserId: (customerUserId: string | null) => Promise<void>
}

const useUser = (): UseUser => {
  const state: State = reactive({
    channelId: ChannelId.Web,
    channelUserId: '',
    customerUserId: '',
    language: '',
    initialized: false,
    operatingSystem: 'desktop',
  })

  const getOperatingSystem = () => {
    const userAgent = window.navigator.userAgent
    const deviceDetector = new DeviceDetector()
    const device = deviceDetector.parse(userAgent)

    if (device.os?.name === 'iOS') {
      return 'ios'
    }

    if (device.os?.name === 'Android') {
      return 'android'
    }

    return 'desktop'
  }

  const initialize = async () => {
    const { state: project } = useProject()

    tryGetStateFromQueryString()
    if (state.channelId === ChannelId.Web) {
      state.channelUserId = getCookie(channelUserIdCookie) || uuid()
      setCookie(channelUserIdCookie, state.channelUserId)
    }

    state.operatingSystem = getOperatingSystem()

    try {
      if (window.location.search) {
        const queryParams = new URLSearchParams(window.location.search)
        const queryLang = queryParams.get('lang')

        if (queryLang) {
          localStorage.setItem('mock-user-language', queryLang)
        }
      }

      state.language =
        localStorage.getItem('mock-user-language') || project.defaultLanguage
      state.initialized = true
    } catch (error) {
      console.error(error)
    }
  }

  const updateUserLanguage = async (lang: string) => {
    const { state: project } = useProject()
    let updatedLang = lang
    if (!project.languages.includes(lang)) updatedLang = project.defaultLanguage
    try {
      localStorage.setItem('mock-user-language', updatedLang)
      state.language = updatedLang
    } catch (error) {
      Promise.reject(error)
    }
  }

  const pinRegistration = async (customerUserId: string) => {
    return {} as PinResponse

    // const { state: project } = useProject()
    // try {
    //   const pinRequest: PinRequest = {
    //     customerId: project.projectName,
    //     customerUserId,
    //     lang: {
    //       lng: state.language,
    //       encoding: 'UTF-8',
    //     },
    //   }
    //   const result = await axios.post<PinResponse>(
    //     platformProxy(`registration/pin`),
    //     pinRequest,
    //   )
    //   return Promise.resolve(result.data)
    // } catch (error) {
    //   return Promise.reject(error)
    // }
  }

  const confirmRegistration = async (type: RegType, pin?: string) => {
    // const { state: project } = useProject()
    // try {
    //   const confirmRequest: ConfirmRequest = {
    //     customerId: project.projectName,
    //     type,
    //     pin,
    //   }
    //   await axios.post(platformProxy(`registration/confirm`), confirmRequest)
    //   return Promise.resolve()
    // } catch (error) {
    //   return Promise.reject(error)
    // }
  }

  const signOut = async () => {
    localStorage.removeItem('mock-login')
    removeCookie(channelUserIdCookie)
    state.customerUserId = ''
  }

  const tryGetStateFromQueryString = () => {
    if (!window.location.search) {
      return
    }

    const query = Object.fromEntries(
      new URLSearchParams(window.location.search),
    )

    if (query.channelId) {
      state.channelId = query.channelId as ChannelId
    }

    if (query.channelUserId) {
      state.channelUserId = query.channelUserId
    }
  }

  const setCustomerUserId = async (customerUserId: string | null) => {
    state.customerUserId = customerUserId

    if (customerUserId) {
      localStorage.setItem('mock-login', customerUserId)
    } else {
      localStorage.removeItem('mock-login')
    }
  }

  return {
    state,
    initialize,
    updateUserLanguage,
    pinRegistration,
    confirmRegistration,
    signOut,
    setCustomerUserId,
  }
}

const instance = useUser()

export default (): UseUser => instance
