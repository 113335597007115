import { important, px } from 'csx'
import { style } from 'typestyle'

export default style({
  padding: `${px(20)} ${px(28)}`,
  $nest: {
    '*': {
      margin: important(0),
    },
  },
})
