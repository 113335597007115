interface ITableRow {
  subtitle: string
  description: string
}

interface IButton {
  _id: string
  name: string
  payload: string
  payloadType: string
  appearance: string
  icon?: string
  params?: Record<string, string>
}

export enum LeadFormFieldType {
  Name = 'name',
  Surname = 'surname',
  Email = 'email',
  PhoneNumber = 'phoneNumber',
  Company = 'company',
}

interface ICard {
  title: string
  description: string
  imageUrl: string
  buttons: IButton[]

  type?: 'leadForm' | 'trafficCard'
  leadFormId?: string
  leadFormDisabled?: boolean
  readMoreButtonLabel?: string
  formButtonLabel?: string
  formTitle?: string
  formFields?: { fieldType: LeadFormFieldType; mandatory: boolean }[]
  readMore?: {
    title: string
    rows: { subtitle: string; description: string }[]
  }

  //TrafficCard
  verticalImageUrl?: string
  ctaButtonLabel?: string
  trafficType?: string
  ctaButtonAppearance?: string
  ctaButtonLink?: string
  whatsappShare?: boolean
  catalogName?: string
}

// Pages
interface ICarouselMenuPage {
  __typename: 'CarouselPageTranslated'
  title: string
  description: string
  cards: ICard[]
}

interface IErrorPage {
  __typename: 'ErrorPageTranslated'
  title: string
  description: string
  subtitle: string
  iconName: string
  buttons: IButton[]
}

export interface ILearnMorePage {
  __typename: 'LearnMorePageTranslated'
  title: string
  description: string
  buttons: IButton[]
}

export interface IReadMorePage {
  __typename: 'ReadMorePageTranslated'
  title: string
  readMoreRows: ITableRow[]
  buttons: IButton[]
}

interface ILanguageSwitchOption {
  value: string
  text: string
}

interface ILanguageSwitch {
  popupIcon: string
  popupText: string
  buttons: ILanguageSwitchOption[]
  settingsPageItems: ILanguageSwitchOption[]
}
interface ISettingsPage {
  __typename: 'SettingsPageTranslated'
  languageSwitch: ILanguageSwitch
  options: IButton[]
  settingsPageItems: ILanguageSwitchOption[]
}

type IPage = ICarouselMenuPage | IErrorPage | ILearnMorePage | IReadMorePage | ISettingsPage

interface ProductPageQueryResponse {
  productPageTranslated: { pageDetails: IPage }
}

interface ProductPageIdByPageNameQueryResponse {
  getProductPageIdByPageName: { _id: string }
}

export {
  IPage,
  IButton,
  ICard,
  ITableRow,
  ILanguageSwitch,
  ILanguageSwitchOption,
  ICarouselMenuPage,
  ProductPageQueryResponse,
  ProductPageIdByPageNameQueryResponse,
}
