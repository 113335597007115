export default {
  title: {
    en: 'Here Is Your Personal Offer!',
    ka: 'შეთავაზება მხოლოდ შენთვის!',
  },
  subtitle: {
    en: 'Here are all the personal offers for your number:',
    ka: 'დაათვალიერე შენი პერსონალური შეთავაზებები:',
  },
  activate: {
    en: 'Activate for {{amount}} {{currency}}',
    ka: 'გაიაქტიურე {{amount}} {{currency}}',
  },
  readMore: {
    en: 'Read More',
    ka: 'დეტალური ინფორმაცია',
  },
  notNow: {
    en: 'Back',
    ka: 'უკან'
  },
}
