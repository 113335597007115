export default {
  topTitle: {
    en: 'Request Successful',
    ka: 'მოთხოვნა წარმატებით შესრულდა',
  },
  myBalances: {
    en: 'My Balances',
    ka: 'ჩემი ბალანსები',
  },
  beelineApp: {
    en: 'Get MyBeeline App',
    ka: 'ჩამოტვირთე MyBeeline აპლიკაცია',
  },
}
