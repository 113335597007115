export default {
  title: {
    en: 'Confirm Sign Out',
    ka: 'დარწმუნებული ხარ, რომ გსურს გამოსვლა?',
  },
  subtitle: {
    en: 'Stay signed in to enjoy personal offers and browse for free Beeline services.',
    ka: 'დარჩი სისტემაში, რათა ისარგებლო შენზე მორგებული შეთავაზებებით და ბილაინის სერვისებით.',
  },
  notNow: {
    en: 'Not Now',
    ka: 'მოგვიანებით',
  },
}
