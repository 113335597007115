import { style } from 'typestyle'
import { important, percent, px } from 'csx'

const carouselStyle = style({
  position: 'sticky',
  left: 0,
  height: 'fit-content',
  display: 'flex',
  overflowX: 'scroll',
  scrollbarWidth: 'none',
  width: percent(100),
  touchAction: 'none',
  cursor: 'grab',
  '-ms-overflow-style': 'none',
  $nest: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&:active': {
      cursor: 'grabbing',
    },
  },
})

const slideStyle = (spacing: number, cardsAmount: number): string => {
  if (cardsAmount === 1) {
    return style({
      paddingLeft: px(spacing),
      $nest: {
        '#Card': {
          borderRadius: important(20),
        },
      },
    })
  }

  return style({
    $nest: {
      '&:first-child': {
        paddingLeft: px(spacing),
      },
      '&:last-child': {
        paddingRight: px(spacing),
      },
      '#Card': {
        borderRadius: '20px !important',
      },
    },
    $debugName: 'slide',
  })
}

const carouselWrapper = style({
  position: 'relative',
  width: '100%',
  height: '100%',
  $debugName: 'carousel_wrapper',
})

export { carouselWrapper, carouselStyle, slideStyle }
