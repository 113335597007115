import { horizontal } from 'csstips'
import { important, percent, px } from 'csx'
import { style } from 'typestyle'

export const timeoutStyle = style(horizontal, {
  $debugName: 'lockContainer',
  position: 'relative',
  justifyContent: important('start'),
  flexGrow: 100,
  $nest: {
    span: {
      flex: `0 0 ${percent(45)}`,
      textAlign: 'left',
      maxWidth: px(90),
    },
  },
})
