import { style } from 'typestyle'

export const modalContainer = style({
  backgroundColor: '#fff',
})

export const cardButtonStyleNew = style({
  position: 'absolute',
  bottom: '-1px',
  width: '100%',
  height: '100px',
})

export const formTitle = style({
  marginTop: '40px',
  fontSize: '24px',
  fontWeight: 500,
})

export const card = style({
  marginBottom: '120px',
})

export const resultContainer = style({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  paddingBottom: '60px',
})

export const resultTitle = style({
  fontSize: '40px',
  color: '#000B30',
  width: '100%',
  marginBottom: '20px',
})

export const resultDescription = style({
  fontSize: '20px',
  color: '#808080',
  width: '100%',
})

export const resultImage = style({
  marginTop: '45px',
})
