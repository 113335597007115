export default {
  notNow: {
    en: 'Not Now',
    ka: 'მოგვიანებით',
  },
  tryAgain: {
    en: 'Try Again',
    ka: 'კიდევ სცადე',
  },
  topTitle: {
    en: 'Something went wrong for',
    ka: 'დაფიქსირდა ხარვეზი',
  },
  title: {
    en: 'Oops! Your Purchase Failed',
    ka: 'Oops! შეძენა ვერ მოხერხდა.',
  },
  subtitle: {
    en: 'Maybe you are not eligible for this offer OR the system encountered an error.',
    ka: 'სისტემაში მოხდა შეცდომა ან ეს შეთავაზება არ არის შენთვის განკუთვნილი',
  },
}
