import { percent, px, viewHeight } from 'csx'
import { style } from 'typestyle'
import { computed } from 'vue'

import injectStyle from '@/helpers/injectStyle'

export const popupStyle = computed(() => {
  const styles = injectStyle()
  return style({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: px(375),
    width: percent(100),
    boxShadow: '0px 0px 4px rgb(0 0 0 / 25%)',
    borderRadius: px(50),
    backgroundColor: styles?.brandColor,
    padding: px(15),
    color: styles?.ctaTextColor,
    $nest: {
      '*': {
        margin: 0,
      },
      span: {
        marginRight: 'auto',
      },
      img: {
        width: px(32),
        height: px(32),
      },
    },
  })
})

export const popupBackgroundStyle = computed(() => {
  const styles = injectStyle()
  return style({
    height: viewHeight(100),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0,0,0,0.5)',
    color: styles?.ctaTextColor,
    position: 'absolute',
    width: percent(100),
    top: 0,
  })
})
