export default {
  title: {
    en: 'You are Signed Out !',
    ka: 'შენ წარმატებით გამოხვედი სისტემიდან',
  },
  subtitle: {
    en: 'Browse our offers and packages. Sign-in any time to enjoy your personal offers.',
    ka: 'გაეცანი ჩვენს შეთავაზებებს და პაკეტებს. შედი სისტემაში ნებისმიერ დროს და ისარგებლე შენზე მორგებული შეთავაზებებით.',
  },
  browse: {
    en: 'Browse our Best Data packages',
    ka: 'გაეცანი ბილაინის საუკეთესო ინტერნეტ პაკეტებს',
  },
  seeAll: {
    en: 'See All',
    ka: 'ყველას ნახვა',
  },
}
