import { style } from 'typestyle'
import { computed } from 'vue'
import { px } from 'csx'
import injectStyle from '@/helpers/injectStyle'

export const menuContainerStyle = computed(() => {
  const { brandColor } = injectStyle()
  return style({
    minHeight: px(44),
    zIndex: 2,
    display: 'flex',
    bottom: 0,
    cursor: 'pointer',
    background: brandColor,
    width: '100%',
    padding: '12px 0px',
    boxShadow: '0px 0 3px rgba(0, 0, 0, 0.05)',
    $debugName: 'menuContainer',
  })
})
