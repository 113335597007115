import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ff_icon_base = _resolveComponent("ff-icon-base")!
  const _component_ff_header = _resolveComponent("ff-header")!
  const _component_ff_content = _resolveComponent("ff-content")!
  const _component_ff_number_input = _resolveComponent("ff-number-input")!
  const _component_ff_pin_input = _resolveComponent("ff-pin-input")!
  const _component_ff_button = _resolveComponent("ff-button")!
  const _component_TermsAndConditions = _resolveComponent("TermsAndConditions")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ff_icon_base, {
      "icon-url": "https://dev-static.cdn.swashly.eu/assets/beeline.ge/icons/beeline_ge._special_icon5.svg",
      "max-height": 160,
      "max-width": 160,
      style: _normalizeStyle(_ctx.styleContainer('ff-icon-base'))
    }, null, 8, ["style"]),
    _createVNode(_component_ff_header, {
      text: _ctx.t('title'),
      "header-type": "heading5",
      "font-weight": "bold",
      style: _normalizeStyle(_ctx.styleContainer('ff-header'))
    }, null, 8, ["text", "style"]),
    _createVNode(_component_ff_content, {
      text: _ctx.t('subtitle'),
      "content-type": "content2",
      style: _normalizeStyle(_ctx.styleContainer('ff-content'))
    }, null, 8, ["text", "style"]),
    _createVNode(_component_ff_number_input, {
      formItemId: "numberInput",
      hint: _ctx.t('phoneNumberHint'),
      prefix: "+995",
      isRequired: true,
      withPrefix: false,
      isDisabled: false,
      mask: "###-###-###",
      length: 9,
      errors: _ctx.errors,
      info: _ctx.info,
      autofocus: true,
      callback: _ctx.phoneCallback,
      onFormCheck: _ctx.formCheck
    }, null, 8, ["hint", "errors", "info", "callback", "onFormCheck"]),
    (_ctx.showPinInput)
      ? (_openBlock(), _createBlock(_component_ff_content, {
          key: 0,
          text: _ctx.t('enterPin'),
          "content-type": "content2",
          style: _normalizeStyle(_ctx.styleContainer('ff-content'))
        }, null, 8, ["text", "style"]))
      : _createCommentVNode("", true),
    (_ctx.showPinInput)
      ? (_openBlock(), _createBlock(_component_ff_pin_input, {
          key: 1,
          formItemId: "pinInput",
          isRequired: true,
          isDisabled: false,
          resendText: _ctx.resendText,
          errors: _ctx.pinErrors,
          callback: _ctx.pinCallback,
          onFormCheck: _ctx.formCheck,
          onResendPin: _ctx.resendPin
        }, null, 8, ["resendText", "errors", "callback", "onFormCheck", "onResendPin"]))
      : _createCommentVNode("", true),
    _createVNode(_component_ff_button, {
      appearance: "default",
      type: "button_default",
      name: _ctx.t('confirm'),
      disabled: _ctx.buttonDisabled,
      style: _normalizeStyle(_ctx.styleContainer('ff-button')),
      onButtonClicked: _ctx.handleClick
    }, null, 8, ["name", "disabled", "style", "onButtonClicked"]),
    _createVNode(_component_TermsAndConditions)
  ], 64))
}