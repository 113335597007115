export const LANGUAGES = [
  {
    label: 'Afar',
    value: 'aa',
  },
  {
    label: 'Afrikaans',
    value: 'af',
  },
  {
    label: 'Akan',
    value: 'ak',
  },
  {
    label: 'Albanian',
    value: 'sq',
  },
  {
    label: 'Amharic',
    value: 'am',
  },
  {
    label: 'Arabic',
    value: 'ar',
  },
  {
    label: 'Armenian',
    value: 'hy',
  },
  {
    label: 'Aymara',
    value: 'ay',
  },
  {
    label: 'Azerbaijani',
    value: 'az',
  },
  {
    label: 'Belarusian',
    value: 'be',
  },
  {
    label: 'Bengali',
    value: 'bn',
  },
  {
    label: 'Bislama',
    value: 'bi',
  },
  {
    label: 'Bulgarian',
    value: 'bg',
  },
  {
    label: 'Burmese',
    value: 'my',
  },
  {
    label: 'Chinese',
    value: 'zh',
  },
  {
    label: 'Croatian',
    value: 'hr',
  },
  {
    label: 'Czech',
    value: 'cs',
  },
  {
    label: 'Danish',
    value: 'da',
  },
  {
    label: 'Dutch',
    value: 'nl',
  },
  {
    label: 'Dzongkha',
    value: 'dz',
  },
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Estonian',
    value: 'et',
  },
  {
    label: 'Faroese',
    value: 'fo',
  },
  {
    label: 'Fijian',
    value: 'fj',
  },
  {
    label: 'Finnish',
    value: 'fi',
  },
  {
    label: 'French',
    value: 'fr',
  },
  {
    label: 'Georgian',
    value: 'ka',
  },
  {
    label: 'German',
    value: 'de',
  },
  {
    label: 'Greek',
    value: 'el',
  },
  {
    label: 'Greenlandic',
    value: 'kl',
  },
  {
    label: 'Guarani',
    value: 'gn',
  },
  {
    label: 'Haitian Creole',
    value: 'ht',
  },
  {
    label: 'Hausa',
    value: 'ha',
  },
  {
    label: 'Hebrew',
    value: 'he',
  },
  {
    label: 'Hindi',
    value: 'hi',
  },
  {
    label: 'Hungarian',
    value: 'hu',
  },
  {
    label: 'Icelandic',
    value: 'is',
  },
  {
    label: 'Indonesian',
    value: 'id',
  },
  {
    label: 'Irish',
    value: 'ga',
  },
  {
    label: 'Italian',
    value: 'it',
  },
  {
    label: 'Japanese',
    value: 'ja',
  },
  {
    label: 'Kazakh',
    value: 'kk',
  },
  {
    label: 'Khmer',
    value: 'km',
  },
  {
    label: 'Kinyarwanda',
    value: 'rw',
  },
  {
    label: 'Kirghiz',
    value: 'ky',
  },
  {
    label: 'Korean',
    value: 'ko',
  },
  {
    label: 'Kurdish',
    value: 'ku',
  },
  {
    label: 'Lao',
    value: 'lo',
  },
  {
    label: 'Latin',
    value: 'la',
  },
  {
    label: 'Latvian',
    value: 'lv',
  },
  {
    label: 'Lingala',
    value: 'ln',
  },
  {
    label: 'Lithuanian',
    value: 'lt',
  },
  {
    label: 'Luxembourgish',
    value: 'lb',
  },
  {
    label: 'Macedonian',
    value: 'mk',
  },
  {
    label: 'Malagasy',
    value: 'mg',
  },
  {
    label: 'Malay',
    value: 'ms',
  },
  {
    label: 'Maltese',
    value: 'mt',
  },
  {
    label: 'Maori',
    value: 'mi',
  },
  {
    label: 'Mongolian',
    value: 'mn',
  },
  {
    label: 'Nauru',
    value: 'na',
  },
  {
    label: 'Nepali',
    value: 'ne',
  },
  {
    label: 'Norwegian',
    value: 'no',
  },
  {
    label: 'Pashto',
    value: 'ps',
  },
  {
    label: 'Persian',
    value: 'fa',
  },
  {
    label: 'Polish',
    value: 'pl',
  },
  {
    label: 'Portuguese',
    value: 'pt',
  },
  {
    label: 'Quechua',
    value: 'qu',
  },
  {
    label: 'Romanian',
    value: 'ro',
  },
  {
    label: 'Rundi',
    value: 'rn',
  },
  {
    label: 'Russian',
    value: 'ru',
  },
  {
    label: 'Samoan',
    value: 'sm',
  },
  {
    label: 'Sango',
    value: 'sg',
  },
  {
    label: 'Serbian',
    value: 'sr',
  },
  {
    label: 'Sesotho',
    value: 'st',
  },
  {
    label: 'Shona',
    value: 'sn',
  },
  {
    label: 'Sinhalese',
    value: 'si',
  },
  {
    label: 'Slovak',
    value: 'sk',
  },
  {
    label: 'Slovenian',
    value: 'sl',
  },
  {
    label: 'Somali',
    value: 'so',
  },
  {
    label: 'Spanish',
    value: 'es',
  },
  {
    label: 'Swahili',
    value: 'sw',
  },
  {
    label: 'Swati',
    value: 'ss',
  },
  {
    label: 'Swedish',
    value: 'sv',
  },
  {
    label: 'Tagalog',
    value: 'tl',
  },
  {
    label: 'Tajik',
    value: 'tg',
  },
  {
    label: 'Thai',
    value: 'th',
  },
  {
    label: 'Tigrinya',
    value: 'ti',
  },
  {
    label: 'Tonga',
    value: 'to',
  },
  {
    label: 'Tswana',
    value: 'tn',
  },
  {
    label: 'Turkish',
    value: 'tr',
  },
  {
    label: 'Turkmen',
    value: 'tk',
  },
  {
    label: 'Ukrainian',
    value: 'uk',
  },
  {
    label: 'Urdu',
    value: 'ur',
  },
  {
    label: 'Uzbek',
    value: 'uz',
  },
  {
    label: 'Vietnamese',
    value: 'vi',
  },
  {
    label: 'Wolof',
    value: 'wo',
  },
  {
    label: 'Yoruba',
    value: 'yo',
  },
]
