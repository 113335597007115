// Escape special characters.
const escapeRe = (str: string) => str.replace(/[.*+?^$|[\](){}\\-]/g, '\\$&')

export const getCookie = (
  key: string,
  decoder = decodeURIComponent,
): string => {
  if (typeof key !== 'string' || !key) {
    return ''
  }

  const reKey = new RegExp(`(?:^|; )${escapeRe(key)}(?:=([^;]*))?(?:;|$)`)
  const match = reKey.exec(document.cookie)

  if (match === null) {
    return ''
  }

  return typeof decoder === 'function' ? decoder(match[1]) : match[1]
}

export const setCookie = (key: string, value: string): void => {
  let encoder = encodeURIComponent
  if (typeof encoder === 'object' && encoder !== null)
    encoder = encodeURIComponent

  const valueStr = value
  const newCookie = `${key}=${valueStr}; Max-Age=2147483647`
  document.cookie = newCookie
}

export const removeCookie = (key: string): void => {
  document.cookie = `${key}=; Max-Age=0`
}
