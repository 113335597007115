import * as csstips from 'csstips'
import { px } from 'csx'
import { classes, style } from 'typestyle'
export const { fontWeightBold, horizontallyCenterChildren } = csstips

import { MarginsInt } from '@/models/typography'
import { NestedCSSProperties } from 'typestyle/lib/types'

const makeTypographyStyle = (textStyle: NestedCSSProperties): string =>
  classes(style(textStyle))

const MarginsStyle: MarginsInt = {
  VS0: '0',
  VS1: '32px 0',
  VS2: '24px 0',
  VS3: '20px 0',
  VS4: '18px 0',
  VS5: '16px 0',
  VS6: '14px 0',
  VS7: '12px 0',
  VS8: '10px 0',
  VS9: '8px 0',
  VS10: '4px 0',
}

const markdownStyle = style({
  $nest: {
    ul: {
      margin: 0,
      padding: 0,
      listStylePosition: 'inside',
      listStyleType: 'none',
    },
    li: {
      display: 'flex',
      marginBottom: '8px',
      $nest: {
        '&:last-child': {
          marginBottom: 0,
        },
        '&::before': {
          content: '"•"',
          paddingRight: px(6),
        }
      }
    }
  },
})

export { makeTypographyStyle, MarginsStyle, markdownStyle }
