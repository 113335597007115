export default {
  title: {
    en: 'My Services',
    ka: 'ჩემი მომსახურებები',
  },
  updatedOn: {
    en: 'Updated on ',
    ka: 'განახლებულია ',
  },
  personalOffer: {
    en: 'Personal Offer',
    ka: 'შეთავაზება შენთვის',
  },
  findPackages: {
    en: 'Find Packages',
    ka: 'ყველა შეთავაზება',
  },
  at: {
    en: ' at ',
    ka: ' ზე ',
  },
}
