export interface FormItem<V = unknown> {
  formItemId: string
  value: V
  isRequired: boolean
  valid?: boolean
  precondition?: [string]
  canView?: boolean
}

export interface ErrorType {
  id: string
  message: string
  value: boolean
  modalTitle?: string
  modalSubtitle?: string
}

export enum ModalType {
  ERROR = 'error',
  LOCKOUT = 'lockout',
  INFO = 'info',
  NONE = 'none',
}

export enum State {
  ERROR = 'error',
  VALID = 'valid',
  UNVALIDATED = 'unvalidated',
  LOADING = 'loading',
  LOCKOUT = 'lockout',
}

export interface FormEventResponse {
  expired?: boolean
  invalid?: boolean
  lockout?: boolean
}
