import { gql } from 'graphql-tag'

export const FUNNELS_QUERY = gql`
  query GetFunnelTranslated($id: MongoID, $projectId: MongoID, $language: String) {
    getFunnelTranslated(id: $id, projectId: $projectId, language: $language) {
      _id
      startDate
      endDate
      pageDetails {
        title
        description
        cards {
          _id
          title
          description
          imageUrl
          buttons {
            _id
            name
            payload
            payloadType
            appearance
            params
          }

          type
          leadFormId
          readMore {
            title
            rows {
              subtitle
              description
            }
          }
          readMoreButtonLabel
          formButtonLabel
          formTitle
          formFields {
            fieldType
            mandatory
          }

          ctaButtonLabel
          trafficType
          ctaButtonAppearance
          ctaButtonLink
          whatsappShare
        }
      }
      verticalScrolling
      showTermsOfService
      languages
    }
  }
`
