import { ffWhite } from '@/styles/constants/colors'
import { viewHeight } from 'csx'
import { style } from 'typestyle'

export const pageContainer = (projectBackground?: string) => style({
  width: '100%',
  position: 'fixed',
  top: 0,
  right: 0,
  height: viewHeight(100),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundImage: projectBackground ? `url(${projectBackground})` : '',
  backgroundColor: ffWhite,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 100%',
  transition: 'right .3s ease-in-out',
  zIndex: 9998,
})

export const closeButtonContainer = style({
  display: 'flex',
  justifyContent: 'flex-end',
})

export const closeButton = style({
  marginRight: '35px',
  marginTop: '54px',
})

export const closeButtonStyle = {
  background: 'rgba(0, 0, 0, 0.5)',
  color: '#fff',
  border: 0,
  fontSize: '12px',
  padding: '8px 20px',
  height: '31px',
}

export const contentContainer = style({
  margin: '46px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flex: 1,
})

export const actionButton = style({
  marginBottom: '14px',
})

export const poweredBy = style({
  textAlign: 'center',
  fontSize: '12px',
  color: 'rgba(12, 12, 12, 0.6)',
})
