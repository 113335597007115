export default {
  title: {
    en: 'Confirm to activate!',
    ka: 'დაადასტურე გასააქტიურებლად',
  },
  mainBalance: {
    en: 'Main Balance',
    ka: 'ძირითადი ბალანსი',
  },
  notNow: {
    en: 'Not Now',
    ka: 'მოგვიანებით',
  },
  topup: {
    en: 'Top-up Now',
    ka: 'შეავსე ბალანსი',
  },
}
