import { px } from 'csx'
import { style } from 'typestyle'

export const overlayStyle = style({
  position: 'fixed',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'rgba(0, 0, 0, 0.6)',
  backdropFilter: 'blur(2px)',
  zIndex: 9997,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export const modalContainer = style({
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderRadius: px(20),
  width: px(355.229),
  height: px(665),
  margin: '0px auto',
  position: 'relative',
  overflow: 'hidden',
  $nest: {
    '@media screen and (max-height: 700px)': {
      height: px(515),
    },
  },
})

export const modalContainerE2E = style({
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderRadius: px(20),
  width: '100%',
  height: 'calc(100% - 160px)',
  margin: '116px 14px 42px 14px',
  position: 'relative',
  overflow: 'hidden',
  $nest: {
    '@media screen and (max-height: 700px)': {
      height: px(515),
    },
  },
})

export const contentContainer = style({
  height: px(665),
  overflowX: 'hidden',
  overflowY: 'scroll',
  '-ms-overflow-style': 'none',
  scrollbarWidth: 'none',
  $nest: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '@media screen and (max-height: 700px)': {
      height: px(515),
    },
  },
})

export const closeContainer = style({
  position: 'absolute',
  zIndex: '5',
  top: px(19),
  right: px(16),
})

export const modalCloseButton = style({
  background: 'rgba(0, 0, 0, 0.5)',
  fontSize: px(12),
  color: 'white',
  width: px(95),
  height: px(30),
  borderRadius: px(20),
  border: 0,
})

export const cardContentStyleNew = style({
  padding: '60px 40px 0 40px',
})

export const cardContentStyleE2e = style({
  padding: '85px 32px 0 32px',
})

export const cardButtonStyleNew = style({
  position: 'absolute',
  bottom: '-1px',
  width: '100%',
  height: '100px',
  background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.76) 100%)',
})

export const cardButtonStyleE2e = style({
  position: 'absolute',
  bottom: '-1px',
  width: '100%',
  height: '100px',
  background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.76) 100%)',
})
