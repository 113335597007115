export default {
  title: {
    en: 'Top-up a Beeline number',
    ka: 'ბალანსის შევსება',
  },
  modalTitle: {
    en: 'Entering your number is a 1-time process only.',
    ka: 'ნომრის შეყვანა დაგჭირდება მხოლოდ ერთხელ.',
  },
  modalSubtitle: {
    en: 'We will need your number to connect to your account.',
    ka: 'ამით ჩვენ შევძლებთ დავუკავშირდეთ შენი ნომრის ანგარიშს.',
  },
  paymentBtn: {
    en: 'To payment',
    ka: 'გადახდა',
  },
  anotherBtn: {
    en: 'Use another number',
    ka: 'სხვა ნომრის არჩევა',
  },
}
