export default {
  termsAndConditions: {
    en: 'By continuing to browse, you acknowledge our<br> <a href="{{link}}"> Terms of Service </a> & <a href="{{link}}">Privacy Policy</a>',
    ka: 'დათვალიერების გაგრძელებით ეთანხმები ჩვენს  <a href="{{link}}"> წესებს და პირობებსა </a> & <br><a href="{{link}}"> კონფიდენციალურობის პოლიტიკას </a>',
  },
  termsAndConditionsActivate: {
    en: 'By continuing to activate, you agree with Beeline<br><a href="{{link}}"> Terms & Conditions</a>',
    ka: 'აქტივაციის გაგრძელებით თქვენ ეთანხმებით ბილაინს<br><a href="{{link}}"> წესები და პირობები</a>',
  },
}
