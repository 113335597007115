import axios, { AxiosResponse } from 'axios'
import { RouteLocationNormalizedLoaded } from 'vue-router'
import useUser from './useUser'

interface UseMessenger {
  sendMessengerPayload: (
    route: RouteLocationNormalizedLoaded,
    payload?: string,
  ) => Promise<AxiosResponse>
  closeWebview: () => void
}

const CloseWebviewImageUrl = 'https://dev-static.cdn.swashly.eu/assets/logo.png'
const CloseWebviewText = 'Loading...'

const useMessenger = (): UseMessenger => {
  const { state: user } = useUser()

  const sendMessengerPayload = async (
    route: RouteLocationNormalizedLoaded,
    payload?: string,
  ) => {
    return await axios.post('/messenger/payload', {
      pageId: route.query.messengerPageId,
      channelUserId: user.channelUserId,
      payload: payload || route.query.messengerPayload,
    })
  }

  const closeWebview = () => {
    if (navigator.userAgent.indexOf('Android') > -1) {
      window.location.href = `https://www.messenger.com/closeWindow/?image_url=${CloseWebviewImageUrl}&display_text=${CloseWebviewText}`
      return
    }

    window.MessengerExtensions.requestCloseBrowser(
      () => {
        console.log('closing webview')
      },
      () => {
        console.log('error while closing webview')
      },
    )
  }

  return {
    sendMessengerPayload,
    closeWebview,
  }
}

const instance = useMessenger()

export default (): UseMessenger => instance
