import { FunnelsQueryResponse, FunnelResponse } from './models'
import { FUNNELS_QUERY } from './queries'
import { UseFetch, useFetch } from '../composables/useFetch'
import { IPage } from '../productPage/models'
import { computed, ref, watch } from 'vue'
import pageBuilder from '@/service/pageBuilder'
import useCampaign from '@/composables/useCampaign'
import axios from 'axios'
import { useUser } from '@/composables'
import { useRoute } from 'vue-router'

export type FetchPageContent = UseFetch<{ pageDetails: IPage }>

export default {
  getFunnel: (id: string, projectId: string, language: string): UseFetch<FunnelResponse> => {
    const { state: campaign } = useCampaign()
    const { state: user } = useUser()
    const route = useRoute()
    const leadFormSubmitted = ref<Record<string, boolean> | null>(null)

    const { loading, refetch, result, error } = useFetch<FunnelsQueryResponse>(
      FUNNELS_QUERY,
      { id, projectId, language },
      {
        fetchPolicy: 'cache-and-network',
      }
    )

    const processResult = () => {
      let input = result.value

      if (leadFormSubmitted.value) {
        input.getFunnelTranslated.pageDetails.cards.forEach((x) => {
          if (x.leadFormId) {
            x.leadFormDisabled = leadFormSubmitted.value![x.leadFormId]
          }
        })
      }

      const output = {
        data: {
          startDate: result.value.getFunnelTranslated?.startDate,
          endDate: result.value.getFunnelTranslated?.endDate,
        },
        content: pageBuilder(
          result.value.getFunnelTranslated?.pageDetails,
          result.value.getFunnelTranslated?.verticalScrolling
        ),
        showTermsOfService: result.value.getFunnelTranslated?.showTermsOfService,
      }

      return output
    }

    const pickedResult = computed(() => result.value && processResult())

    watch(
      () => result.value,
      async () => {
        if (result.value) {
          campaign.languages = result.value.getFunnelTranslated.languages

          const leadFormIds = result.value.getFunnelTranslated.pageDetails.cards
            .map((x) => x.leadFormId)
            .filter((x) => x) as string[]
          leadFormSubmitted.value = await checkLeadFormSubmitted(
            leadFormIds,
            user.channelUserId!,
            route.params.funnelId.toString()
          )
        }
      }
    )

    return { loading, refetch, result: pickedResult, error }
  },

  getRawFunnel: (id: string, projectId: string, language: string): UseFetch<FunnelsQueryResponse> =>
    useFetch<FunnelsQueryResponse>(
      FUNNELS_QUERY,
      { id, projectId, language },
      {
        fetchPolicy: 'network-only',
      }
    ),
}

async function checkLeadFormSubmitted(
  leadFormIds: string[],
  channelUserId: string,
  funnelId: string
): Promise<Record<string, boolean>> {
  try {
    const { data } = await axios.post(`${process.env.VUE_APP_NEW_PLATFORM_URL}/api/lead-form-data/checkSubmitted`, {
      leadFormIds,
      channelUserId,
      funnelId,
    })

    return data
  } catch (error) {
    console.log(error)
    return {}
  }
}
