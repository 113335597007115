import { px } from 'csx'
import { style } from 'typestyle'

import { ffWhite } from '@/styles/constants/colors'
import { ModalSize } from '../types/Modal'

export default (size: ModalSize): string =>
  style({
    position: 'fixed',
    display: 'table',
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    zIndex: 9998,
    $nest: {
      '.modalBackground': {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, .3)',
        opacity: '60%',
      },
      '.modalWrapper': {
        display: 'table-cell',
        verticalAlign: 'middle',
        $nest: {
          '.modalContainer': {
            width: size === 'regular' ? px(300) : px(325),
            margin: '0px auto',
            padding: size === 'regular' ? px(8) : px(16),
            backgroundColor: ffWhite,
            borderRadius: '5px',
            boxShadow: '0 2px 8px rgba(0, 0, 0, .33)',
            transition: 'all 1s ease',
            position: 'relative',
            $nest: {
              '.modalCloseButton': {
                position: 'absolute',
                top: 0,
                right: 0,
                background: 'transparent',
                outline: 'none',
                border: 'none',
              },
            },
          },
        },
      },
    },
  })
